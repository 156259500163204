import CustomTable from "Components/Common/CustomTable";
import Button from "Components/Common/Fields/Button";
import CustomSelectField from "Components/Common/Fields/CustomSelectField";
import SearchInputField from "Components/Common/Fields/SearchInputField";
import ShowPageTitle from "Components/Common/ShowPageTitle";
import { TooltipWrapper } from "Components/Common/ToolTipWrapper";
import LeadDetailsModal from "Components/Tasks/LeadDetailsModal";
import {
  emailReportStatus,
  emailStatusOptions,
  emailTypeOptions,
} from "Modules/data";
import { isValidArray, showLeadDateTimeFormat } from "Modules/util";
import { useEmailReportListQuery } from "Redux/Reports/emailReportQuery";
import { setBreadcrumb } from "Redux/themeSlice";
import he from "he";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const Reports = () => {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("all"); // state for selected status for filter
  const [selectedType, setSelectedType] = useState("all"); // state for selected status for filter
  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
    search: "",
    status: "",
  });
  const [isLeadDetailsOpen, setIsLeadDetailsOpen] = useState(false); //state for lead detail modal
  const [leadData, setLeadData] = useState({}); // state for lead id
  const [isOpenNestModal, setIsOpenNestModal] = useState({
    leadDetailsModal: false,
    addTaskModal: false,
    addCallLogModal: false,
    addDripModal: false,
    sendMailModal: false,
    makeCallModal: false,
    birthdayModal: false,
    deleteCallModal: false,
    deleteTaskModal: false,
    deleteDripModal: false,
    deleteNoteModal: false,
    deleteHistoryModal: false,
    deletePhoneModal: false,
    changePhoneStatusModal: false,
    changeEmailStatusModal: false,
    deleteEmailModal: false,
    AssignAISuggestedDripModal: false,
    AISuggestedDripApproveModal: false,
    reAssignDripModal: false,
    removeDripModal: false,
    dripHoverEditModal: false,
    mergeLeadModal: false,
  });

  const {
    data: emailReportList,
    isLoading: isEmailReportListLoading,
    isSuccess: isEmailReportListSuccess,
    refetch,
  } = useEmailReportListQuery(filter);

  useEffect(() => {
    if (!isEmailReportListLoading) {
      let list = emailReportList?.data?.sentEmailList?.response;
      if (isValidArray(list)) {
        setRows(list);
      } else {
        setRows([]);
      }
    }
  }, [emailReportList]);

  const decodeHTMl = (preview) => {
    const decodedData = he.decode(preview || "<p><br/></p>");
    return decodedData;
  };

  const columns = [
    {
      field: "lead",
      headerName: "Lead Name",
      width: 300,
      editable: false,
      resizable: true,
      sortable: false,
      cellRenderer: (cellInfo) => {
        let leadId = cellInfo?.data?.lead?._id;
        let lead = cellInfo?.data?.lead;
        return (
          <div
            onClick={() => {
              setLeadData({ leadId: leadId });
              setIsLeadDetailsOpen(true);
            }}
            title={lead?.firstName + " " + lead?.lastName || ""}
            className="text-blue-500 font-semibold hover:cursor-pointer hover:underline"
          >
            {lead?.firstName + " " + lead?.lastName}
          </div>
        );
      },
    },
    {
      field: "from",
      headerName: "From",
      width: 250,
      minWidth: 150,
      editable: false,
      resizable: true,
      sortable: true,
      cellRenderer: (cellInfo) => {
        return <span title={cellInfo?.data?.from}>{cellInfo?.data?.from}</span>;
      },
    },
    {
      field: "to",
      headerName: "To",
      width: 250,
      minWidth: 150,
      editable: false,
      resizable: true,
      sortable: true,
      cellRenderer: (cellInfo) => {
        return <span title={cellInfo?.data?.to}>{cellInfo?.data?.to}</span>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      minWidth: 150,
      editable: false,
      resizable: true,
      sortable: false,
      cellRenderer: (cellInfo) => {
        let status = cellInfo?.data?.status || "";
        let reason = cellInfo?.data?.reason || "";
        return status ? (
          <div title={status} className="flex items-center space-x-1">
            <div
              className={`${
                status === "delivered" ? "bg-green-400" : "bg-red-400"
              } !w-3 !h-3 rounded-full `}
            ></div>
            <span className="capitalize">
              {status + `${status === "not delivered" ? ` (${reason})` : ""}`}
            </span>
          </div>
        ) : (
          <div className="text-xs mt-3 font-semibold text-center">-</div>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      width: 150,
      minWidth: 150,
      editable: false,
      resizable: true,
      sortable: false,
      cellRenderer: (cellInfo) => {
        return <span title={cellInfo?.data?.type} className="capitalize">{cellInfo?.data?.type}</span>;
      },
    },
    {
      field: "subject",
      headerName: "Subject",
      width: 200,
      minWidth: 150,
      editable: false,
      resizable: true,
      sortable: false,
      cellRenderer: (cellInfo) => {
        return (
          <span title={cellInfo?.data?.subject}>{cellInfo?.data?.subject}</span>
        );
      },
    },
    {
      field: "body",
      headerName: "Body",
      width: 500,
      minWidth: 150,
      editable: false,
      resizable: true,
      sortable: false,
      cellRenderer: (cellInfo) => {
        return <TooltipWrapper message={decodeHTMl(cellInfo?.data?.body)} />;
      },
    },
    {
      field: "isOpened",
      headerName: "Opened",
      width: 100,
      minWidth: 100,
      editable: false,
      resizable: true,
      sortable: false,
      cellRenderer: (cellInfo) => {
        let isOpened = cellInfo?.data?.isOpened || "";
        return isOpened ? <span>Yes</span> : <span>No</span>;
      },
    },
    {
      field: "openedCount",
      headerName: "Opened Count",
      width: 140,
      minWidth: 100,
      editable: false,
      resizable: true,
      sortable: false,
      cellRenderer: (cellInfo) => {
        let openedCount = cellInfo?.data?.openedCount;
        return openedCount ? (
          <div className="font-semibold text-gray-700">{openedCount}</div>
        ) : (
          <div className="text-xs mt-3 font-semibold">0</div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Sent Date",
      width: 250,
      editable: false,
      resizable: true,
      minWidth: 150,
      sortable: true,
      cellRenderer: (cellInfo) => {
        let createdDate = cellInfo?.data?.createdAt;
        return createdDate ? (
          <div className="font-semibold text-gray-700 ">
            {showLeadDateTimeFormat(createdDate)}
          </div>
        ) : (
          <div className="text-xs mt-3 font-semibold text-center"> - </div>
        );
      },
    },
    {
      field: "openedAt",
      headerName: "Opened Date",
      width: 250,
      minWidth: 150,
      editable: false,
      resizable: true,
      sortable: false,
      cellRenderer: (cellInfo) => {
        let openedDate = cellInfo?.data?.openedAt;
        return openedDate ? (
          <div className="font-semibold text-gray-700">
            {showLeadDateTimeFormat(openedDate)}
          </div>
        ) : (
          <div className="text-xs mt-3 font-semibold text-center">-</div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          title: "Reports",
          path: "/reports",
        },
      ])
    );
  }, []);

  const handleResetFilter = () => {
    setFilter({
      ...filter,
      search: "",
      status: "",
      type: "",
    });
    setSearch("");
    setSelectedStatus("all");
    setSelectedType("all");
  };

  // updating filter
  useEffect(() => {
    if (selectedStatus) {
      if (selectedStatus !== "all") {
        setFilter({ ...filter, status: selectedStatus, page: 1 });
      } else {
        setFilter({ ...filter, status: "" });
      }
    }
  }, [selectedStatus]);

  // updating filter
  useEffect(() => {
    if (selectedType) {
      if (selectedType !== "all") {
        setFilter({ ...filter, type: selectedType, page: 1 });
      } else {
        setFilter({ ...filter, type: "" });
      }
    }
  }, [selectedType]);

  return (
    <>
      <LeadDetailsModal
        isOpen={isLeadDetailsOpen}
        setIsOpen={setIsLeadDetailsOpen}
        isLeadId={leadData?.leadId}
        setIsOpenNestModal={setIsOpenNestModal}
        isOpenNestModal={isOpenNestModal}
      />
      <ShowPageTitle title={"reports"} />
      <div className="flex justify-between items-center mb-2">
        <SearchInputField
          setSearchInput={(value) => setSearch(value)}
          searchInput={search}
          globalSearch={(value) =>
            setFilter({
              ...filter,
              search: value,
            })
          }
        />

        <div className="flex items-center">
          <div className="flex space-x-2">
            <div className="flex">
              <Button
                isDisable={
                  filter?.search === "" &&
                  selectedStatus === "all" &&
                  selectedType === "all"
                    ? true
                    : false
                }
                title={"Reset Filter"}
                action={() => handleResetFilter()}
                className="btn-orange-sm"
              />
            </div>
            <div className="flex items-center ">
              <label className="text-sm font-semibold">Type:</label>
              <div>
                <CustomSelectField
                  options={emailTypeOptions}
                  name={"email_type"}
                  setNewSelectedOption={setSelectedType}
                  classNames="!w-[300px] text-sm "
                  placeholder="Select Email Status"
                  onlyValue={true}
                  initialvalue={selectedType}
                />
              </div>
            </div>
            <div className="flex items-center ">
              <label className="text-sm font-semibold">Status:</label>
              <div>
                <CustomSelectField
                  options={emailReportStatus}
                  name={"email_status"}
                  setNewSelectedOption={setSelectedStatus}
                  classNames="!w-[300px] text-sm "
                  placeholder="Select Email Status"
                  onlyValue={true}
                  initialvalue={selectedStatus}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 flex flex-col bg-white">
        <CustomTable
          listName={"emailReportList"}
          rows={rows}
          columns={columns}
          filter={filter}
          pagination={true}
          setFilter={setFilter}
          isLoading={isEmailReportListLoading}
          totalRecords={
            isEmailReportListSuccess
              ? emailReportList?.data?.sentEmailList?.totalCount
              : 0
          }
        />
      </div>
    </>
  );
};

export default Reports;
