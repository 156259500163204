import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import SpinnerButton from "Components/Common/Fields/SpinnerButton";
import useEscapeClose from "Components/Common/Hooks/useEscapeClose";
import Select from "Components/Drips/DripInputs/Select";
import { sentAfterOptions } from "Modules/data";
import {
  getTimeZone,
  isValidObject,
  showErrorMsg,
  showSuccessMsg,
} from "Modules/util";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import {
  useDripScheduleTimeQuery,
  useRescheduleDripLeadMutation,
} from "Redux/Leads/Drip/drip";

export default function RescheduleDripModal(props) {
  const { isOpen, setIsOpen, dripToReschedule, leadId, dripListRefetch } =
    props;
  const [rescheduleDrip, { isLoading: isRescheduleDripLoading }] =
    useRescheduleDripLeadMutation();

  useEscapeClose(setIsOpen); // Custom hook to close a modal when the Escape key is pressed.
  const [fieldValues, setFieldValues] = useState({
    time: "",
    type: "",
  });
  const [dripDripinfo, setDripInfo] = useState();

  useEffect(() => {
    setFieldValues({
      time: dripDripinfo?.time,
      type: dripDripinfo?.type,
      isDripTimeChange: false,
    });
  }, [dripToReschedule, dripDripinfo]);

  const { data: getDripScheduleTime, isLoading: isDripScheduleTimeLoading } =
    useDripScheduleTimeQuery({
      dripId: dripToReschedule?.dripId,
      leadId: leadId,
    });

  useEffect(() => {
    if (!isDripScheduleTimeLoading) {
      const dripTimeAndNodeInfo = getDripScheduleTime?.data?.dripScheduleTime;

      if (
        isValidObject(dripTimeAndNodeInfo) &&
        dripTimeAndNodeInfo?.type === "success"
      ) {
        let scheduleTime = moment(dripTimeAndNodeInfo?.scheduleTime)
          .tz(getTimeZone())
          .format("MMMM Do, YYYY hh:mm A");
        setDripInfo({
          scheduleTime: scheduleTime,
          time: dripTimeAndNodeInfo?.time,
          type: dripTimeAndNodeInfo?.timeType,
          nodeId: dripTimeAndNodeInfo?.nodeId,
          nodeType: dripTimeAndNodeInfo?.nodeType,
        });
      } else {
        setDripInfo({});
        showErrorMsg(dripTimeAndNodeInfo?.message);
        setIsOpen(false);
        dripListRefetch();
      }
    }
  }, [getDripScheduleTime]);

  //manage sent after time on change of time type ( minutes , hour , days)
  function manageSentAfterTime(type) {
    let time = "1";
    if (type === "minutes" && fieldValues?.time === "0") {
      time = fieldValues?.time;
    }
    setFieldValues({
      ...fieldValues,
      type: type,
      time: time,
      isDripTimeChange: validateTimeChange(time, type),
    });
  }

  // Validating Time Change
  function validateTimeChange(time, type) {
    if (type === dripDripinfo?.type && time === dripDripinfo?.time) {
      return false;
    } else {
      return true;
    }
  }

  function setInput(inputValue) {
    inputValue = Number(inputValue);

    if (fieldValues?.type === "hours" && inputValue <= 24) {
      setFieldValues({
        ...fieldValues,
        time: inputValue.toString(),
        isDripTimeChange: validateTimeChange(
          inputValue.toString() || "0",
          "hours"
        ),
      });
    }
    if (fieldValues?.type === "days" && inputValue <= 100) {
      setFieldValues({
        ...fieldValues,
        time: inputValue.toString(),
        isDripTimeChange: validateTimeChange(
          inputValue.toString() || "0",
          "days"
        ),
      });
    }
    if (fieldValues?.type === "minutes" && inputValue <= 60) {
      setFieldValues({
        ...fieldValues,
        time: inputValue.toString(),
        isDripTimeChange: validateTimeChange(
          inputValue.toString() || "0",
          "minutes"
        ),
      });
    }
    if (fieldValues?.type === "weeks" && inputValue <= 99) {
      setFieldValues({
        ...fieldValues,
        time: inputValue.toString(),
        isDripTimeChange: validateTimeChange(
          inputValue.toString() || "0",
          "weeks"
        ),
      });
    }
    if (fieldValues?.type === "months" && inputValue <= 99) {
      setFieldValues({
        ...fieldValues,
        time: inputValue.toString(),
        isDripTimeChange: validateTimeChange(
          inputValue.toString() || "0",
          "months"
        ),
      });
    }
    if (fieldValues?.type === "years" && inputValue <= 10) {
      setFieldValues({
        ...fieldValues,
        time: inputValue.toString(),
        isDripTimeChange: validateTimeChange(
          inputValue.toString() || "0",
          "years"
        ),
      });
    }
  }

  async function handleReScheduleDrip() {
    const playload = {
      dripId: dripToReschedule?.dripId,
      leadId: leadId,
      time: fieldValues?.time,
      type: fieldValues?.type,
      nodeId: dripDripinfo?.nodeId,
    };
    try {
      const res = await rescheduleDrip(playload);
      const rescheduleDripLeadRes = res?.data?.data?.rescheduleDripLead;
      if (isValidObject(rescheduleDripLeadRes)) {
        const { type, message } = rescheduleDripLeadRes;
        if (type === "success") {
          showSuccessMsg(message);
          setFieldValues({ ...fieldValues, isDripTimeChange: false });
        } else if (type === "error") {
          showErrorMsg(message);
          setIsOpen(false);
        }
      } else {
        showErrorMsg("Something went wrong!");
      }
    } catch (error) {
      showErrorMsg("Error occurred while Re-Schedule Drip");
    }
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[9999]" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-5xl border-b-2 py-2 transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-5 ">
                <div className="mt-1 border-b-2 py-2 sm:mt-0 flex justify-between items-center">
                  <div className="sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-800 flex justify-between items-center"
                    >
                      Reschedule {dripToReschedule?.dripTitle} Drip
                    </Dialog.Title>
                  </div>
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 "
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-5">
                  <div>
                    <span>
                      {" "}
                      <p>
                        <b>Drip: </b> <span>{dripToReschedule?.dripTitle}</span>
                      </p>
                      <p>
                        <b>Next: </b>{" "}
                        <span className="space-x-1">
                          <span>#{dripDripinfo?.nodeId}</span>
                          <span>{dripDripinfo?.nodeType}</span>
                        </span>
                      </p>
                      <p>
                        <b>Schedule Time: </b>
                        <span>{dripDripinfo?.scheduleTime}</span>
                      </p>
                    </span>
                  </div>
                  <div className="flex space-x-2">
                    <label className="pt-2">
                      Next{" "}
                      {dripToReschedule?.templateType === "message"
                        ? "text"
                        : dripToReschedule?.templateType}{" "}
                      which will be sent in
                    </label>
                    <div className="">
                      <div className="flex">
                        <input
                          type="text"
                          className="text-right text-sm rounded-l-md w-[50px] h-[38px] ring-0 outline-none border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 "
                          value={fieldValues?.time}
                          onChange={(e) => setInput(e.target.value)}
                          min={1}
                          max={
                            fieldValues?.type === "minutes"
                              ? 60
                              : fieldValues?.type === "hours"
                              ? 24
                              : fieldValues?.type === "days"
                              ? 100
                              : fieldValues?.type === "weeks"
                              ? 99
                              : fieldValues?.type === "months"
                              ? 99
                              : 10
                          }
                          maxLength={3}
                        />
                        <Select
                          className="!w-[200px] !rounded-r-lg"
                          options={sentAfterOptions}
                          value={fieldValues?.type}
                          onChange={(e) => manageSentAfterTime(e.target.value)}
                        />
                      </div>
                      {fieldValues.time === "0" &&
                        fieldValues.type !== "minutes" && (
                          <span className="text-sm text-start text-red-600">
                            can not set 0 for {fieldValues?.type}
                          </span>
                        )}
                    </div>
                  </div>
                  <div></div>
                  <div className="flex justify-end">
                    <SpinnerButton
                      className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                      title={"Reschedule Drip"}
                      toolTip={"Reschedule Drip"}
                      action={() => handleReScheduleDrip()}
                      type={"button"}
                      loading={isRescheduleDripLoading}
                      isDisable={
                        !fieldValues?.isDripTimeChange ||
                        isRescheduleDripLoading
                      }
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
