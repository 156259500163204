import Select from "Components/Drips/DripInputs/Select";
import { sentAfterOptions } from "Modules/data";

export default function ScheduleTime(props) {
  const {
    fieldValues,
    setFieldValues = () => {},
    validateTimeChange = () => {},
    manageSentAfterTime = () => {},
    node,
  } = props;

  function setInput(inputValue) {
    inputValue = Number(inputValue);

    if (fieldValues?.type === "minutes" && inputValue <= 60) {
      setFieldValues({
        ...fieldValues,
        time: inputValue.toString(),
        isDripTimeChange: validateTimeChange(
          inputValue.toString() || "0",
          "minutes"
        ),
      });
    }
    if (fieldValues?.type === "hours" && inputValue <= 24) {
      setFieldValues({
        ...fieldValues,
        time: inputValue.toString(),
        isDripTimeChange: validateTimeChange(
          inputValue.toString() || "0",
          "hours"
        ),
      });
    }
    if (fieldValues?.type === "days" && inputValue <= 100) {
      setFieldValues({
        ...fieldValues,
        time: inputValue.toString(),
        isDripTimeChange: validateTimeChange(
          inputValue.toString() || "0",
          "days"
        ),
      });
    }
    if (fieldValues?.type === "weeks" && inputValue <= 99) {
      setFieldValues({
        ...fieldValues,
        time: inputValue.toString(),
        isDripTimeChange: validateTimeChange(
          inputValue.toString() || "0",
          "weeks"
        ),
      });
    }
    if (fieldValues?.type === "months" && inputValue <= 99) {
      setFieldValues({
        ...fieldValues,
        time: inputValue.toString(),
        isDripTimeChange: validateTimeChange(
          inputValue.toString() || "0",
          "months"
        ),
      });
    }
    if (fieldValues?.type === "years" && inputValue <= 10) {
      setFieldValues({
        ...fieldValues,
        time: inputValue.toString(),
        isDripTimeChange: validateTimeChange(
          inputValue.toString() || "0",
          "years"
        ),
      });
    }
  }

  return (
    <div className="mt-5 transition-all">
      <div className="flex space-x-2">
        <label className="pt-2 text-sm">
          Here is a preview of the first{" "}
          {node?.type === "message" ? "text" : node?.type} which will be sent in
        </label>
        <div className="">
          <div className="flex">
            <input
              type="text"
              className="text-right text-sm rounded-l-md w-[50px] h-[38px] ring-0 outline-none border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 "
              value={fieldValues?.time}
              onChange={(e) => setInput(e.target.value)}
              min={1}
              max={
                fieldValues?.type === "minutes"
                  ? 60
                  : fieldValues?.type === "hours"
                  ? 24
                  : fieldValues?.type === "days"
                  ? 100
                  : fieldValues?.type === "weeks"
                  ? 99
                  : fieldValues?.type === "months"
                  ? 99
                  : 10
              }
              maxLength={3}
            />
            <Select
              className="!w-[200px] !rounded-r-lg"
              options={sentAfterOptions}
              value={fieldValues?.type}
              onChange={(e) => manageSentAfterTime(e.target.value)}
            />
          </div>
          {fieldValues?.time === "0" && fieldValues?.type !== "minutes" && (
            <span className="text-sm text-start text-red-600">
              can not set 0 for {fieldValues?.type}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
