import { Dialog, Transition } from "@headlessui/react";
import { ShieldExclamationIcon } from "@heroicons/react/24/outline";
import { ExclamationCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import GroupBySelect from "Components/Common/Fields/GroupBySelect";
import SpinnerButton from "Components/Common/Fields/SpinnerButton";
import TextArea from "Components/Common/Fields/TextArea";
import useEscapeClose from "Components/Common/Hooks/useEscapeClose";
import ScheduleTime from "Components/Common/ScheduleTime";
import CkEditor from "Components/Common/TextEditor/CkEditor";
import { useFormik } from "formik";
import {
  createSliceOptions,
  isValidArray,
  isValidObject,
  replaceNewlinesWithBR,
  setLocalStorage,
  showErrorMsg,
  showSuccessMsg,
} from "Modules/util";
import { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { useGetNodesAndEdgesListQuery } from "Redux/Drip/drip";
import {
  useGetDripByLeadIdQuery,
  useGetDripListByLeadIdMutation,
} from "Redux/Leads/Drip/drip";
import {
  useAddDripPreviewMutation,
  useGenerateOpenAIDripResponseMutation,
  useScheduleDripLeadTaskMutation,
  useSuggestDripMutation,
} from "Redux/Leads/lead";
import * as Yup from "yup";

export default function AISuggestedDripApproveModal(props) {
  const {
    isOpen,
    setIsOpen,
    leadId,
    lead,
    selectedDrip,
    setSelectedDrip,
    setIsModifyDripFlowModal = () => {},
    setSavedNodeTime = () => {},
  } = props;
  useEscapeClose(setIsOpen); // Custom hook to close a modal when the Escape key is pressed.

  const dripList = useSelector((s) => s?.option?.dripList);
  const [dripOptions, setDripOptions] = useState([]);
  const [currentPlayPauseDrips, setCurrentPlayPauseDrips] = useState([]); // state for getting id's of currently playing or paused
  const [getDripsList] = useGetDripListByLeadIdMutation();
  const [isRunningDripSelect, setIsRunningDripSelect] = useState(false);
  const [fieldValues, setFieldValues] = useState({
    time: "",
    type: "",
  });
  const [node, setNode] = useState();
  const CkEditorRef = useRef();

  const [suggestAIDrip, { isLoading: isSuggestDripLoading }] =
    useSuggestDripMutation();

  const [aiSuggestedDripAssign, { isLoading: isAiSuggestedDripAssign }] =
    useScheduleDripLeadTaskMutation();

  const [
    generateOpenAIDripPreview,
    { isLoading: isGenerateOpenAIDripPreviewLoading },
  ] = useGenerateOpenAIDripResponseMutation();

  const [generateAddDripPreview, { isLoading: isAddDripPreviewLoading }] =
    useAddDripPreviewMutation();

  const {
    data: getDripByLeadId,
    isLoading: isDripListLoading,
    isFetching: isDripListFetching,
    refetch: dripListRefetch,
  } = useGetDripByLeadIdQuery({ _id: leadId }, { skip: leadId ? false : true });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      dripId: "",
      isDripTimeChange: false,
    },
    validationSchema: Yup.object().shape({
      dripId: Yup.string().required("Please Select Drip"),
      message: Yup.string().required("Preview Message Is Required"),
      subject:
        node?.type === "mail" &&
        Yup.string().required("Preview Subject Is Required"),
    }),
    onSubmit: async (values) => {
      let payload = {
        isFilter: false,
        Filter: {},
        dripId: formik?.values?.dripId,
        isDripTimeChange: fieldValues?.isDripTimeChange,
        isSelectedAll: false,
        leadId: leadId,
        message: values?.message,
        time: fieldValues?.time,
        type: fieldValues?.type,
        ...(values?.subject && { subject: values?.subject }),
      };
      try {
        const res = await aiSuggestedDripAssign(payload);
        const scheduleDripLeads = res?.data?.data?.scheduleDripLeadTask;
        if (isValidObject(scheduleDripLeads)) {
          const { type, message } = scheduleDripLeads;
          if (type === "success") {
            showSuccessMsg(message);
          } else if (type === "error") {
            showErrorMsg(message);
          }
        } else {
          showErrorMsg("Error occurred while assign lead!");
        }
      } catch (error) {
        showErrorMsg("Error occurred while assign lead");
      }
      setIsOpen(false);
      setSelectedDrip(null);
    },
  });

  const { data: getNodesAndEdges, isLoading: isNodeAndedgesLoading } =
    useGetNodesAndEdgesListQuery(
      { id: formik?.values?.dripId },
      { skip: !formik?.values?.dripId ? true : false }
    );

  useEffect(() => {
    if (!isNodeAndedgesLoading) {
      let nodes = getNodesAndEdges?.data?.nodeAndEdgesDetails?.nodesData;
      let node = {};
      node = nodes?.[0];
      setFieldValues({
        time: node?.data?.time,
        type: node?.data?.type,
        isDripTimeChange: false,
      });
      setNode(node);
    }
  }, [getNodesAndEdges, isNodeAndedgesLoading]);

  async function generateOpenAIDripResponse() {
    let payload = {
      dripId: formik?.values?.dripId,
      leadId: leadId,
      nodeId: node?.id,
      nodeType: node?.type,
      isSuggestedDrip: false,
    };
    const res = await generateOpenAIDripPreview(payload);
    const openAIDripPreview = res?.data?.data?.generateOpenAIDripResponse;
    try {
      if (isValidObject(openAIDripPreview)) {
        const { type, message, response, subject } = openAIDripPreview;
        if (type === "success") {
          showSuccessMsg(message);
          if (node?.type === "message") {
            formik?.setFieldValue("message", response);
            manageLocalStorage({
              nodeId: node?.id,
              node: node?.type,
              preview: replaceNewlinesWithBR(response),
            });
          } else {
            formik?.setFieldValue("message", replaceNewlinesWithBR(response));
            formik?.setFieldValue("subject", subject);
            manageLocalStorage({
              nodeId: node?.id,
              node: node?.type,
              preview: replaceNewlinesWithBR(response),
              subject: subject,
            });
          }
        } else if (type === "error") {
          showErrorMsg(message);
        }
      } else {
        showErrorMsg("Error occurred while generating preview!");
      }
    } catch (error) {
      showErrorMsg("Error occurred while generating preview");
    }
  }

  async function generatDripPreview() {
    let payload = {
      dripId: formik?.values?.dripId,
      leadId: leadId,
      nodeId: node?.id,
    };
    try {
      const res = await generateAddDripPreview(payload);
      const DripPreview = res?.data?.data?.addDripPreview;
      if (isValidObject(DripPreview)) {
        const { type, message, preview, subject } = DripPreview;
        if (type === "success") {
          if (node?.type === "message") {
            formik?.setFieldValue("message", preview);
            manageLocalStorage({
              nodeId: node?.id,
              node: node?.type,
              preview: preview,
            });
          } else {
            formik?.setFieldValue("message", replaceNewlinesWithBR(preview));
            formik?.setFieldValue("subject", subject);
            manageLocalStorage({
              nodeId: node?.id,
              node: node?.type,
              preview: preview,
              subject: subject,
            });
          }
          showSuccessMsg(message);
        } else if (type === "error") {
          showErrorMsg(message);
        }
      } else {
        showErrorMsg("Error occurred while generating preview!");
      }
    } catch (error) {
      showErrorMsg("Error occurred while generating preview");
    }
  }

  function manageLocalStorage(previewObj) {
    setLocalStorage("suggestedDripSavedPreview", JSON.stringify(previewObj));
  }

  useEffect(() => {
    if (formik?.values?.dripId && node) {
      if (isValidObject(node?.data)) {
        if (node?.data?.isOpenAI) {
          generateOpenAIDripResponse();
        } else {
          generatDripPreview();
        }
      }
    }
  }, [formik?.values?.dripId, node]);

  // use effect for creating Grouped option
  useEffect(() => {
    const AIDripOption = dripList?.filter(
      (drip) =>
        drip?.DripAIPrompt &&
        drip?.status === true &&
        drip?.isSuggested === true &&
        !currentPlayPauseDrips?.includes(drip?.id)
    );
    const customDripOption = dripList?.filter(
      (drip) =>
        !drip?.DripAIPrompt &&
        drip?.status === true &&
        drip?.isSuggested === true &&
        !currentPlayPauseDrips?.includes(drip?.id)
    );
    const groupedOptions = [
      ...(isValidArray(AIDripOption)
        ? [
            {
              label: "AI Drips",
              options: createSliceOptions(AIDripOption, "title", "id"),
            },
          ]
        : []),
      ...(isValidArray(customDripOption)
        ? [
            {
              label: "Custom Drip",
              options: createSliceOptions(customDripOption, "title", "id"),
            },
          ]
        : []),
    ];
    if (isValidArray(AIDripOption) || isValidArray(customDripOption)) {
      setDripOptions(groupedOptions);
    }
  }, [dripList, currentPlayPauseDrips]);

  // manage change of drip selection drop down
  function handleChange(dripInfo) {
    let selectedDrip = dripList?.filter((drip) => drip?.id === dripInfo?.value);
    setSelectedDrip(...selectedDrip);
    formik?.setFieldValue("dripId", selectedDrip?.[0]?.id);
  }

  async function handleSuggestDrip() {
    try {
      const res = await suggestAIDrip({
        leadId: leadId,
      });
      const suggestedDripRes = res?.data?.data?.suggestDrip;
      if (isValidObject(suggestedDripRes)) {
        const { type, message } = suggestedDripRes;
        if (type === "success") {
          if (suggestedDripRes?.suggestedDripId) {
            let selectDrip = dripList?.filter(
              (drip) => drip?.id === suggestedDripRes?.suggestedDripId
            );
            setSelectedDrip(...selectDrip);
            formik?.setFieldValue("dripId", selectDrip?.[0]?.id);
          }
          showSuccessMsg(message);
        } else if (type === "error") {
          showErrorMsg(message);
        }
      } else {
        showErrorMsg("Something went wrong!");
      }
    } catch (error) {
      showErrorMsg("Error occurred while suggesting drip");
    }
  }

  const checkRunningDrip = async () => {
    try {
      const res = await getDripsList({ _id: leadId });
      if (res?.data?.data?.getDripByLeadId?.type === "success") {
        // Filter and map to get the IDs of running drips (not terminated and is playing)
        const runningDripIds =
          res?.data?.data?.getDripByLeadId?.singleDripResponse?.[0]?.dripsResponse
            ?.filter(
              (drip) =>
                drip?.isPlay &&
                !drip?.isTerminated &&
                drip?.leadDripStatus !== "completed"
            )
            ?.map((drip) => drip?.dripId);
        setIsRunningDripSelect(runningDripIds?.includes(selectedDrip?.id)); // Check if the selected drip ID is among the running drips
      }
    } catch (error) {
      console.error("Error fetching drips:", error);
      setIsRunningDripSelect(false);
    }
  };

  useEffect(() => {
    // Check if selectedDrip and leadId are valid before making the API call
    if (isValidObject(selectedDrip) && leadId && selectedDrip) {
      checkRunningDrip();
    } else {
      setIsRunningDripSelect(false);
    }
    // Cleanup function to reset the state
    return () => {
      setIsRunningDripSelect(false);
    };
  }, [selectedDrip, leadId]);

  function handleSkipNodes() {
    if (isValidObject(selectedDrip)) {
      setIsModifyDripFlowModal(true);
      setIsOpen(false);
    }
  }

  useEffect(() => {
    if (!isDripListLoading) {
      let currentDripList =
        getDripByLeadId?.data?.getDripByLeadId?.singleDripResponse?.[0]
          ?.dripsResponse;
      setCurrentPlayPauseDrips(
        currentDripList
          ?.filter(
            (drip) => drip?.dripSortOrder === 2 || drip?.dripSortOrder === 3
          )
          ?.map((drip) => drip?.dripId)
      );
    }
  }, [getDripByLeadId, isDripListLoading]);

  // Validating Time Change
  function validateTimeChange(time, type) {
    if (type === node?.data?.type && time === node?.data?.time) {
      return false;
    } else {
      return true;
    }
  }

  //manage sent after time on change of time type ( minutes , hour , days)
  function manageSentAfterTime(type) {
    let time = "1";
    if (type === "minutes" && fieldValues?.time === "0") {
      time = fieldValues?.time;
    }
    setFieldValues({
      ...fieldValues,
      type: type,
      time: time,
      isDripTimeChange: validateTimeChange(time, type),
    });
  }

  function isRegenerateButtonDisable() {
    if (isValidObject(node?.data)) {
      if (node?.data?.isOpenAI) {
        return false;
      } else {
        return true;
      }
    }
  }

  useEffect(() => {
    setSavedNodeTime({ ...fieldValues, id: node?.id });
  }, [fieldValues]);

  useEffect(() => {
    if (isOpen) {
      setSelectedDrip({});
      setNode({});
    }
    // if (isOpen && lead?.leadResponse?.[0]?.suggestedDrip) {
    //   handleChange({ value: lead?.leadResponse?.[0]?.suggestedDrip });
    // }
    return () => {
      formik?.resetForm();
      setNode({});
    };
  }, [isOpen]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[1111]" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-5xl border-b-2 py-2 transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-5 ">
                <div className="mt-1 border-b-2 py-2 sm:mt-0 flex justify-between items-center">
                  <div className="sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-800 flex justify-between items-center"
                    >
                      Approve AI Suggested Drip
                    </Dialog.Title>
                  </div>
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 "
                    onClick={() => {
                      setIsOpen(false);
                      formik?.resetForm();
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-5">
                  <div className="flex space-x-2 mb-2">
                    <lable className="font-bold mt-3.5 mr-9">
                      Select Drip :
                    </lable>
                    <div className="w-[460px]">
                      <GroupBySelect
                        options={dripOptions}
                        handleChange={handleChange}
                        placeholder="Select Drip"
                        initialvalue={
                          isValidObject(selectedDrip)
                            ? createSliceOptions([selectedDrip], "title", "id")
                            : null
                        }
                      />
                    </div>
                    <div className="flex justify-end items-center mt-2">
                      <SpinnerButton
                        type="submit"
                        toolTip="Suggest Drip Using AI"
                        title="Suggest Drip"
                        action={() => handleSuggestDrip()}
                        className="btn-orange rounded-lg text-sm font-medium disabled:cursor-not-allowed disabled:bg-orange-400 disabled:opacity-75"
                        loading={isSuggestDripLoading}
                        isDisable={false}
                      />
                    </div>
                  </div>
                  {selectedDrip?.description ? (
                    <div className="space-x-2">
                      <lable className="font-bold">Drip Description :</lable>
                      <lable>{selectedDrip?.description}</lable>
                    </div>
                  ) : (
                    <></>
                  )}
                  {formik?.values?.dripId ? (
                    <>
                      <ScheduleTime
                        fieldValues={fieldValues}
                        setFieldValues={setFieldValues}
                        validateTimeChange={validateTimeChange}
                        manageSentAfterTime={manageSentAfterTime}
                        node={node}
                      />
                      <div className="mt-3 transition-all ">
                        <span className="flex font-medium">
                          Preview:{" "}
                          {isGenerateOpenAIDripPreviewLoading ||
                          isAddDripPreviewLoading ? (
                            <span>
                              <label className="ml-2">Generating Preview</label>
                              <span className="mt-0.5">
                                <PulseLoader size={3} color="#000000" />
                              </span>
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                        {!isGenerateOpenAIDripPreviewLoading &&
                        !isAddDripPreviewLoading ? (
                          <div>
                            {node?.type === "message" ? (
                              <TextArea
                                name="message"
                                formik={formik}
                                className="border-1 border-gray-300 bg-gray-100 placeholder-gray-700 "
                                rows={6}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="message"
                                placeholder="Preview Message"
                                isNotRemoveExtraSpace={true}
                              />
                            ) : (
                              <div>
                                {node?.type === "mail" && (
                                  <div className="my-3">
                                    <label className="text-sm font-medium text-semibold mb-3">
                                      Subject:{" "}
                                    </label>
                                    <div className="text-sm">
                                      <input
                                        type="text"
                                        id="subject"
                                        name="subject"
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-[#475361] focus:border-[#475361] sm:text-sm"
                                        onChange={formik.handleChange}
                                        onBlur={(e) => {
                                          const values = e.target.value;
                                          const removeSpace = values
                                            .replace(/\s+/g, " ")
                                            .trim();
                                          formik.handleChange(e);
                                          formik.setFieldValue(
                                            "subject",
                                            removeSpace
                                          );
                                        }}
                                        value={formik?.values?.subject}
                                        placeholder="Subject Template"
                                      />
                                    </div>
                                  </div>
                                )}
                                <div>
                                  {node?.type === "mail" && (
                                    <label className="text-sm font-medium text-semibold">
                                      Body:
                                    </label>
                                  )}
                                  <div className="mb-4">
                                    <CkEditor
                                      formik={formik}
                                      name={"message"}
                                      editorRef={CkEditorRef}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      {selectedDrip?.isSameFrequency ? (
                        <div className="bg-blue-100 mb-1 border-l-4  border-blue-500 text-blue-700 p-2 rounded-md shadow-md m-1">
                          <div className="flex items-center">
                            <ExclamationCircleIcon className="h-5 w-5 text-blue-700 mr-1" />
                            <p className="font-bold text-xs">Note:</p>
                            <p className="ml-1 text-xs">
                              Here is a preview of the first
                              {node?.type === "message"
                                ? " text"
                                : " " + node?.type}{" "}
                              which will be{" "}
                              {node?.type === "task" ? " created" : " sent"} in{" "}
                              {fieldValues?.time} {fieldValues?.type} and Drip
                              will process other nodes every{" "}
                              {selectedDrip?.freqTime} {selectedDrip?.freqType}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                {isRunningDripSelect && selectedDrip && (
                  <div className="bg-yellow-100 mt-5 border-l-4  border-yellow-500 text-yellow-700 p-2 rounded-md shadow-md">
                    <div className="flex items-center">
                      <ShieldExclamationIcon className="h-5 w-5 text-yellow-700 mr-2" />
                      <p className="font-bold text-xs">Warning:</p>
                      <p className="ml-1 mt-1 text-xs">
                        This lead is already on the selected drip campaign. If
                        you reassign it, the lead will start from the beginning
                        again.
                      </p>
                    </div>
                    <p className="ml-8 mt-1 font-semibold text-sm">
                      Are you sure you want to reassign the drip?
                    </p>
                  </div>
                )}
                <div className="flex justify-end pt-6">
                  {node?.data?.isOpenAI && formik?.values?.dripId ? (
                    <SpinnerButton
                      className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                      title={"Regenerate Preview"}
                      action={() => generateOpenAIDripResponse()}
                      type={"button"}
                      loading={false}
                      isDisable={
                        isRegenerateButtonDisable() ||
                        isGenerateOpenAIDripPreviewLoading
                      }
                    />
                  ) : (
                    <></>
                  )}
                  {formik?.values?.dripId ? (
                    <SpinnerButton
                      className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                      title={"See Drip Campaign"}
                      action={() =>
                        window.open(
                          `/drip-flow/${formik?.values?.dripId}`,
                          "_blank"
                        )
                      }
                      type={"button"}
                      loading={false}
                      isDisable={
                        isAddDripPreviewLoading ||
                        isGenerateOpenAIDripPreviewLoading
                      }
                    />
                  ) : (
                    <></>
                  )}
                  <SpinnerButton
                    className="inline-flex w-full justify-center rounded-md btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                    title={"Skip Nodes"}
                    action={() => handleSkipNodes()}
                    type={"submit"}
                    isDisable={!isValidObject(selectedDrip)}
                    toolTip="Skip Nodes"
                  />
                  <SpinnerButton
                    className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto "
                    title={"Assign"}
                    action={() => formik?.handleSubmit()}
                    type={"submit"}
                    loading={isAiSuggestedDripAssign}
                    isDisable={!isValidObject(selectedDrip)}
                    toolTip={
                      isValidObject(selectedDrip)
                        ? "Approve Suggested Drip"
                        : "Please Select Drip"
                    }
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
