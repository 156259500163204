import { Switch } from "@headlessui/react";
import LoadingButton from "Components/Common/Buttons/LoadingButton";
import AISuggestedDripApproveModal from "Components/Leads/Modal/AISuggestedDripApproveModal";
import AssignAISuggestedDripModal from "Components/Leads/Modal/AssignAISuggestedDripModal";
import ModifyDripFlowModal from "Components/Leads/Modal/ModifyDripFlowModal";
import {
  isPermission,
  isValidArray,
  showErrorMsg,
  showSuccessMsg,
} from "Modules/util";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLeadFollowUpMutation } from "Redux/Leads/lead";
import {
  useGenerateOpenAISMSResponseMutation,
  useToggleOpenAIAutomationMutation,
} from "Redux/Leads/openAI";

const AIAutoButton = ({
  formik,
  isAiReply,
  smsData,
  setIsAiReply,
  leadId,
  lead,
  showOptedOutValidation,
  setIsOpenNestModal = () => {},
}) => {
  const [toggleOpenAIAutoReply] = useToggleOpenAIAutomationMutation();
  const [previousDripFlowData, setPreviousDripFlowData] = useState();
  const [autoFollowUp, { isLoading: isAutoFollowUpLoading }] =
    useLeadFollowUpMutation();
  const [generateAISmsResponse, { isLoading }] =
    useGenerateOpenAISMSResponseMutation();
  const [isAutoFollowUp, setIsAutoFollowUp] = useState(
    lead?.leadResponse?.[0]?.isFollowUp || false
  );
  const [isAISuggestedDripApproveModal, setIsAISuggestedDripApproveModal] =
    useState(false);
  const [isAISuggestedDripAssignModal, setIsAISuggestedDripAssignModal] =
    useState(false);
  const [selectedDrip, setSelectedDrip] = useState([]); //for suggested selected drip
  const userPermissions = useSelector((state) => state?.user?.permission); // Get user permissions from Redux store
  const [isModifyDripFlowModal, setIsModifyDripFlowModal] = useState(false); // state for modify drip flow modal
  const [scheduleDripData, setScheduleDripData] = useState({});
  const [savedNodeTime, setSavedNodeTime] = useState({});

  // Handle toggle of AI reply
  const handleAutoAIReply = async () => {
    setIsAiReply((prev) => !prev); // Toggle the state of isAiReply
    try {
      const res = await toggleOpenAIAutoReply({
        isAiReply: !isAiReply,
        id: leadId,
      }); // Call the toggleOpenAIAutoReply mutation
      const responseData = res?.data?.data?.toggleOpenAIAutomation; // Extract response data
      if (responseData) {
        if (responseData?.type === "success") {
          showSuccessMsg(responseData?.message); // Show success message if response is successful
        } else if (responseData?.type === "errors") {
          // Show error message and revert isAiReply if response indicates error
          showErrorMsg(responseData.message);
          setIsAiReply((prev) => !prev);
        }
      }
    } catch (error) {
      showErrorMsg("Error:");
    }
  };

  // Handle generation of AI response
  const handleAutoAIGenerate = async () => {
    try {
      const res = await generateAISmsResponse({ id: leadId }); // Call the generateAISmsResponse function
      const generateOpenAIResponse = res?.data?.data?.generateOpenAIResponse; // Extract response data
      // Check if response data exists
      if (generateOpenAIResponse?.type === "success") {
        showSuccessMsg(generateOpenAIResponse?.message); // Show success message and set message field value if response is successful
        formik.setFieldValue("message", generateOpenAIResponse?.response);
      } else if (generateOpenAIResponse?.type === "errors") {
        showErrorMsg(generateOpenAIResponse?.message); // Show error message and revert isAiReply if response indicates error
        setIsAiReply((prev) => !prev);
      }
    } catch (error) {
      showErrorMsg("Error");
    }
  };
  const handleCheckboxChange = async (checked) => {
    let playLoad = { isFollowUp: checked, leadId: leadId };
    try {
      // Making an API call to autoFolloUp the lead
      const res = await autoFollowUp(playLoad);
      const leadFollowUpRes = res?.data?.data?.leadFollowUp;
      if (leadFollowUpRes) {
        const { type, message } = leadFollowUpRes; // Showing success or error message based on the response
        if (type === "success") {
          showSuccessMsg(message);
          setIsAutoFollowUp(checked);
        } else if (type === "error") {
          showErrorMsg(message);
        }
      }
    } catch (error) {
      showErrorMsg("Error While Changing Auto Follow-up");
    }
  };

  useEffect(() => {
    setIsAutoFollowUp(lead?.leadResponse?.[0]?.isFollowUp || false);
  }, [lead?.leadResponse?.[0]?.isFollowUp]);

  useEffect(() => {
    setIsOpenNestModal((prev) => ({
      ...prev,
      AssignAISuggestedDripModal: isAISuggestedDripAssignModal,
      AISuggestedDripApproveModal: isAISuggestedDripApproveModal,
      ModifyDripFlowModal: isModifyDripFlowModal,
    }));
  }, [
    isAISuggestedDripAssignModal,
    isAISuggestedDripApproveModal,
    isModifyDripFlowModal,
  ]);

  return (
    <div className="flex justify-end">
      <div className="flex justify-end space-x-[5px]">
        <span className="flex items-center ">
          {/* * AI Auto-Response Switch */}
          <span className="text-gray text-xs text-nowrap">
            AI Auto-Response
          </span>
          <Switch
            checked={isAiReply}
            onChange={() => {
              if (smsData?.length !== 0) {
                handleAutoAIReply();
              }
            }}
            title={
              showOptedOutValidation
                ? "Number Is Opted-Out"
                : // : !isValidArray(lead?.agentResponse)
                // ? "Lead don't have agent"
                !isValidArray(smsData)
                ? "AI Auto Responses can only be generated once Message sent to the Lead"
                : "Generate AI Auto-Response"
            }
            disabled={
              showOptedOutValidation
                ? true
                : !isValidArray(lead?.agentResponse)
                ? true
                : !isValidArray(smsData)
                ? true
                : !formik?.values?.fromNo || !formik?.values?.phones
                ? true
                : false
            }
            className={`${
              isAiReply ? "bg-green-600" : "bg-gray-200"
            } relative inline-flex h-5 w-10  items-center rounded-full ml-1 disabled:cursor-not-allowed ${
              showOptedOutValidation
                ? "opacity-70 cursor-not-allowed"
                : !isValidArray(lead?.agentResponse)
                ? "opacity-70 cursor-not-allowed"
                : !isValidArray(smsData)
                ? "opacity-70 cursor-not-allowed"
                : ""
            }`}
          >
            <span className="sr-only text-nowrap">Enable AI Replay</span>
            <span
              className={`${
                isAiReply ? "translate-x-[22px]" : "translate-x-1"
              } inline-block h-4 w-4 transform rounded-full bg-white transition duration-300 ease-in-out`}
            />
          </Switch>
        </span>
        {isPermission(userPermissions, "send_sms") && (
          <span className="flex items-center ">
            {/* * Auto Follow-up Switch */}
            <span className="text-gray text-xs text-nowrap">
              Auto Follow-up
            </span>
            <Switch
              checked={isAutoFollowUp}
              onChange={(isChecked) => {
                handleCheckboxChange(isChecked);
              }}
              title={
                showOptedOutValidation
                  ? "Number Is Opted-Out"
                  : !isValidArray(smsData)
                  ? "Auto Follow-up can only be open once Message sent to the Lead"
                  : "Toggle Auto Follow-up"
              }
              disabled={
                isAutoFollowUpLoading || showOptedOutValidation
                  ? true
                  : !isValidArray(smsData)
                  ? true
                  : false
              }
              className={`${
                isAutoFollowUp ? "bg-green-600" : "bg-gray-200"
              } relative inline-flex h-5 w-10  items-center rounded-full ml-1 disabled:cursor-not-allowed `}
            >
              <span className="sr-only">Enable Auto Follow-up</span>
              <span
                className={`${
                  isAutoFollowUp ? "translate-x-[22px]" : "translate-x-1"
                } inline-block h-4 w-4 transform rounded-full bg-white transition duration-300 ease-in-out`}
              />
            </Switch>
          </span>
        )}
        {/* AI Suggestion Button */}
        <LoadingButton
          label="AI Suggestion"
          isLoading={isLoading}
          className="btn-sms !px-[5px] text-xs rounded disabled:cursor-not-allowed disabled:opacity-75 text-nowrap"
          title={
            smsData?.length === 0
              ? "AI Suggestions can only be generated once Message sent to the Lead"
              : showOptedOutValidation
              ? "Number is Opted-out"
              : "Generate AI Suggestion"
          }
          isDisable={
            smsData?.length === 0
              ? true
              : showOptedOutValidation
              ? true
              : !formik?.values?.fromNo || !formik?.values?.phones
              ? true
              : false
          }
          action={() => {
            if (!isLoading) {
              if (smsData?.length !== 0 && !showOptedOutValidation) {
                handleAutoAIGenerate();
              }
            }
          }}
        />
        {isPermission(userPermissions, "schedule_drip_leads") && (
          <LoadingButton
            label="Drip Suggestion"
            className="btn-sms !px-[5px] text-xs rounded disabled:cursor-not-allowed disabled:opacity-75 text-nowrap"
            title={
              smsData?.length === 0
                ? "Drip Suggestion can only be generated once Message sent to the Lead"
                : ""
            }
            isDisable={smsData?.length === 0}
            action={() => {
              setIsAISuggestedDripApproveModal(true);
            }}
          />
        )}
        {isAISuggestedDripApproveModal && (
          <AISuggestedDripApproveModal
            isOpen={isAISuggestedDripApproveModal}
            setIsOpen={setIsAISuggestedDripApproveModal}
            setIsAISuggestedDripAssignModal={setIsAISuggestedDripAssignModal}
            // task={task}
            leadId={leadId}
            lead={lead}
            selectedDrip={selectedDrip}
            setSelectedDrip={setSelectedDrip}
            setIsModifyDripFlowModal={setIsModifyDripFlowModal}
            setScheduleDripData={setScheduleDripData}
            setSavedNodeTime={setSavedNodeTime}
          />
        )}
        {isAISuggestedDripAssignModal && (
          <AssignAISuggestedDripModal
            isOpen={isAISuggestedDripAssignModal}
            setIsOpen={setIsAISuggestedDripAssignModal}
            setIsAISuggestedDripApproveModal={setIsAISuggestedDripApproveModal}
            // refetch={refetchLead}
            task={{ leadId: leadId }}
            selectedDrip={selectedDrip}
            setSelectedDrip={setSelectedDrip}
            scheduleDripData={scheduleDripData}
            setIsModifyDripFlowModal={setIsModifyDripFlowModal}
            setPreviousDripFlowData={setPreviousDripFlowData}
            setSavedNodeTime={setSavedNodeTime}
            savedNodeTime={savedNodeTime}
          />
        )}
        {isModifyDripFlowModal && (
          <ModifyDripFlowModal
            isOpen={isModifyDripFlowModal}
            setIsOpen={setIsModifyDripFlowModal}
            dripId={selectedDrip?.id}
            scheduleDripData={scheduleDripData}
            setScheduleDripData={setScheduleDripData}
            setIsAssignDripPreviewModal={setIsAISuggestedDripAssignModal}
            previousDripFlowData={previousDripFlowData}
          />
        )}
      </div>
    </div>
  );
};

export default AIAutoButton;
