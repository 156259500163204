export const gqlBody = {
  getAllUsers(payload) {
    return {
      query: `query Users($input: GetAllUserInput!) {
        users(input: $input) {
          response {
            _id
            email
            photo
            name
            emailVerified
            emailVerificationToken
            createdAt
            updatedAt
            agentTwilioNumber
            isAccountSetUP
            role {
              _id
              roleName
              permission
              isDeleted
              isAdmin
              isDefault
              isLender
              createdAt
              updatedAt
            }
            appPassword
            companyName
            financialInstitute
            phone
            totalAssignedAgent
            totalAssignedLender
          }
          totalCount
          type
          message
          code
        }
      }`,
      variables: {
        input: {
          limit: payload?.limit,
          column: payload?.column,
          page: payload?.page,
          sort: payload?.sort,
          search: payload?.search,
        },
      },
    };
  },
  getUser(payload) {
    return {
      query: `query Users($input: GetUserInput!) {
        user(input: $input) {
          response {
            _id
            email
            photo
            firstName
            lastName
            companyName
            role
            emailVerified
            emailVerificationToken
            createdAt
            updatedAt
            financialInstitute
            phone
            agentTwilioNumber
            type
            appPassword
          }
          type
          message
          code
        }
      }`,
      variables: {
        input: {
          _id: payload,
        },
      },
    };
  },
  getRole(payload) {
    return {
      query: `query GetRole($input: ListRoleInput!) {
        listRoles(input: $input) {
          response {
            _id
            roleName
            permission
            isDeleted
            isAdmin
            isDefault
            createdAt
            updatedAt
            isLender
          }
          totalRecords
          type
          message
          code
        }
      }`,
      variables: {
        input: {
          limit: payload?.limit ?? 1000,
          _id: payload?.id,
        },
      },
    };
  },
  addUser(payload) {
    return {
      query: `mutation Mutation($input: AddUserInput!) {
        addUser(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  editUser(payload) {
    return {
      query: `mutation EditUser($input: EditUserInput!) {
        editUser(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: {
          _id: payload?.id,
          email: payload?.email,
          firstName: payload?.firstName,
          lastName: payload?.lastName,
          role: payload?.role,
          phone: payload?.phone,
          financialInstitute: payload?.financialInstitute,
          phoneNumber: payload?.phoneNumber,
          companyName: payload?.companyName,
        },
      },
    };
  },
  deleteUser(payload) {
    return {
      query: `mutation Mutation($input: DeleteUserInput!) {
        deleteUser(input: $input) {
          type
          message
          code
          totalAssignedLeads
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  LenderList(payload) {
    return {
      query: `query Query($input: LenderListInput!) {
        lender(input: $input) {
          code
          message
          response {
            _id
            name
            financialInstitute
            createdAt
            updatedAt
            email
            photo
            emailVerified
            emailVerificationToken
            role
          }
          totalCount
          type
        }
      }`,
      variables: {
        input: payload ?? {},
      },
    };
  },
  listOfAvailableNumbers(payload) {
    return {
      query: `query GetListOfAvailableNumbers($input: AvailableNumbersInput) {
        getListOfAvailableNumbers(input: $input) {
          getNumbers {
            friendlyName
            phoneNumber
            capabilities {
              voice
              SMS
              MMS
            }
          }
        }
      }`,
      variables: {
        input: payload ?? {},
      },
    };
  },
  AgentAndAgentManager(payload) {
    return {
      query: `query AgentAndAgentManager {
        AgentAndAgentManager {
          response {
            _id
            name
            role
            roleId
            agentTwilioNumber
          }
          totalCount
          type
          message
          code
        }
      }`,
      variables: {
        input: payload ?? {},
      },
    };
  },
  buyNumberForUser(payload) {
    return {
      query: `mutation BuyNumberForAgent($input: BuyNumberForAgentInput) {
        buyNumberForAgent(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  addUserNumber(payload) {
    return {
      query: `mutation AddUserTwilioNumber($input: AddUserTwilioNumberInput) {
        AddUserTwilioNumber(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  getSingleRole(payload) {
    return {
      query: `query GetRole($input: GetRoleInput!) {
        getRole(input: $input) {
          response {
            _id
            roleName
            permission
            isDeleted
            isAdmin
            isDefault
            isLender
            createdAt
            updatedAt
          }
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  UpdateUsersPassword(payload) {
    return {
      query: `mutation UpdateUsersPassword($input: UpdateUsersPasswordInput) {
        updateUsersPassword(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  checkUser(payload) {
    return {
      query: `query CheckUserAccount($input: GetUserInput!) {
        checkUserAccount(input: $input) {
          code
          message
          response {
            _id
            email
            photo
            name
            firstName
            lastName
            companyName
            role
            emailVerified
            emailVerificationToken
            createdAt
            updatedAt
            financialInstitute
            phone
            agentTwilioNumber
            type
            isAccountSetUP
            appPassword
            emailSignature
            dripFromEmail
            bulkFromEmail
            individualFromEmail
            isGoogleApp
          }
          type
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  checkUserBeforeDelete(payload) {
    return {
      query: `query CheckUserBeforeDelete($input: CheckUserBeforeDeleteInput) {
        checkUserBeforeDelete(input: $input) {
          type
          message
          code
          totalAssignedLeads
          role
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  ResendEmailAccountSetup(payload) {
    return {
      query: `mutation ResendEmailAccountSetup($input: ResendEmailAccountSetupInput) {
      resendEmailAccountSetup(input: $input) {
        code
        message
        type
      }
    }`,
      variables: {
        input: payload,
      },
    };
  },
  SwitchUser(payload) {
    return {
      query: `mutation SwitchUser($input: SwitchUserInput!) {
  switchUser(input: $input) {
    code
    message
    response {
      _id
      email
      photo
      name
      firstName
      lastName
      companyName
      role
      emailVerified
      emailVerificationToken
      createdAt
      updatedAt
      financialInstitute
      phone
      agentTwilioNumber
      type
      isAccountSetUP
      appPassword
      emailSignature
      dripFromEmail
    }
    token
    type
  }
}`,
      variables: {
        input: payload,
      },
    };
  },
};
