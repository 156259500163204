import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import SelectField from "Components/Common/Fields/SelectField";
import SpinnerButton from "Components/Common/Fields/SpinnerButton";
import TextArea from "Components/Common/Fields/TextArea";
import useEscapeClose from "Components/Common/Hooks/useEscapeClose";
import ScheduleTime from "Components/Common/ScheduleTime";
import CkEditor from "Components/Common/TextEditor/CkEditor";
import { useFormik } from "formik";
import {
  getOptionData,
  hasNonEmptyValuesInObj,
  isValidObject,
  removeUndefined,
  replaceNewlinesWithBR,
  setLocalStorage,
  showErrorMsg,
  showSuccessMsg,
} from "Modules/util";
import { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { useGetDripQuery, useGetNodesAndEdgesListQuery } from "Redux/Drip/drip";
import {
  useAddDripPreviewMutation,
  useGenerateOpenAIDripResponseMutation,
  useScheduleDripLeadsInputMutation,
} from "Redux/Leads/lead";
import * as Yup from "yup";

export default function AssignDripModal({
  isOpen,
  setIsOpen,
  leadId,
  lead,
  filterData,
  setIsModifyDripFlowModal = () => {},
  setDripForModifyFlow = () => {},
  setSavedNodeTime = () => {},
  ActiveDripList = [],
}) {
  useEscapeClose(setIsOpen); // Custom hook to close a modal when the Escape key is pressed.
  const [assignLeadTodrip, { isLoading: isAssignDripLoading }] =
    useScheduleDripLeadsInputMutation();
  const [activeDripList, setActiveDripList] = useState(); // state for Active Drips
  const [fieldValues, setFieldValues] = useState({
    time: "",
    type: "",
  });
  const [node, setNode] = useState();
  const [drip, setDrip] = useState({});
  const quillRef = useRef();

  const [
    generateOpenAIDripPreview,
    { isLoading: isGenerateOpenAIDripPreviewLoading },
  ] = useGenerateOpenAIDripResponseMutation();

  const [generateAddDripPreview, { isLoading: isAddDripPreviewLoading }] =
    useAddDripPreviewMutation();

  const dripList = useSelector((s) => s?.option.dripList);
  // filter active lead from drip list
  useEffect(() => {
    let currentPlayPauseDrips = ActiveDripList?.dripsResponse
      ?.filter((data) => data?.dripSortOrder === 2 || data?.dripSortOrder === 3)
      ?.map((data) => data?.dripId);

    let activeDrips = dripList
      ?.filter((data) => data?.status === true)
      ?.filter((data) => !currentPlayPauseDrips?.includes(data?.id));

    let dripOption = [...getOptionData(activeDrips)];
    setActiveDripList(dripOption);
  }, [dripList]);

  // Validating Time Change
  function validateTimeChange(time, type) {
    if (type === node?.data?.type && time === node?.data?.time) {
      return false;
    } else {
      return true;
    }
  }

  //manage sent after time on change of time type ( minutes , hour , days)
  function manageSentAfterTime(type) {
    let time = "1";
    if (type === "minutes" && fieldValues?.time === "0") {
      time = fieldValues?.time;
    }
    setFieldValues({
      ...fieldValues,
      type: type,
      time: time,
      isDripTimeChange: validateTimeChange(time, type),
    });
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      dripId: "",
      isDripTimeChange: false,
    },
    validationSchema: Yup.object().shape({
      dripId: Yup.string().required("Please Select Drip"),
      message: Yup.string().required("Preview Message Is Required"),
    }),

    onSubmit: async (values) => {
      handleSubmit(values);
    },
  });

  const { data: getNodesAndEdges, isLoading: isNodeAndedgesLoading } =
    useGetNodesAndEdgesListQuery(
      { id: formik?.values?.dripId },
      { skip: !formik?.values?.dripId ? true : false }
    );

  const { data: getDrip, isLoading: isDripLoading } = useGetDripQuery(
    {
      id: formik?.values?.dripId,
    },
    {
      skip: !formik?.values?.dripId ? true : false,
    }
  );

  useEffect(() => {
    if (!isDripLoading) {
      let drip = getDrip?.data?.drip?.drip;
      setDrip(drip);
    }
  }, [getDrip, isDripLoading]);

  useEffect(() => {
    if (!isNodeAndedgesLoading) {
      let node = getNodesAndEdges?.data?.nodeAndEdgesDetails?.nodesData?.[0];
      setFieldValues({
        time: node?.data?.time,
        type: node?.data?.type,
        isDripTimeChange: false,
      });
      setNode(node);
    }
  }, [getNodesAndEdges, isNodeAndedgesLoading]);

  async function generateOpenAIDripResponse() {
    let payload = {
      dripId: formik?.values?.dripId,
      leadId: lead?.leadResponse?.[0]?._id,
      nodeId: node?.id,
      nodeType: node?.type,
      isSuggestedDrip: false,
    };
    const res = await generateOpenAIDripPreview(payload);
    const openAIDripPreview = res?.data?.data?.generateOpenAIDripResponse;
    try {
      if (isValidObject(openAIDripPreview)) {
        const { type, message, response, subject } = openAIDripPreview;
        if (type === "success") {
          showSuccessMsg(message);
          if (node?.type === "message") {
            formik?.setFieldValue("message", response);
            manageLocalStorage({
              nodeId: node?.id,
              node: node?.type,
              preview: replaceNewlinesWithBR(response),
            });
          } else {
            formik?.setFieldValue("message", replaceNewlinesWithBR(response));
            formik?.setFieldValue("subject", subject);
            manageLocalStorage({
              nodeId: node?.id,
              node: node?.type,
              preview: replaceNewlinesWithBR(response),
              subject: subject,
            });
          }
        } else if (type === "error") {
          showErrorMsg(message);
        }
      } else {
        showErrorMsg("Error occurred while generating preview!");
      }
    } catch (error) {
      showErrorMsg("Error occurred while generating preview");
    }
  }

  async function generatDripPreview() {
    let payload = {
      dripId: formik?.values?.dripId,
      leadId: lead?.leadResponse?.[0]?._id,
      nodeId: node?.id,
    };
    try {
      const res = await generateAddDripPreview(payload);
      const DripPreview = res?.data?.data?.addDripPreview;
      if (isValidObject(DripPreview)) {
        const { type, message, preview, subject } = DripPreview;
        if (type === "success") {
          if (node?.type === "message") {
            formik?.setFieldValue("message", preview);
            manageLocalStorage({
              nodeId: node?.id,
              node: node?.type,
              preview: preview,
            });
          } else {
            formik?.setFieldValue("message", replaceNewlinesWithBR(preview));
            formik?.setFieldValue("subject", subject);
            manageLocalStorage({
              nodeId: node?.id,
              node: node?.type,
              preview: preview,
              subject: subject,
            });
          }
          showSuccessMsg(message);
        } else if (type === "error") {
          showErrorMsg(message);
        }
      } else {
        showErrorMsg("Error occurred while generating preview!");
      }
    } catch (error) {
      showErrorMsg("Error occurred while generating preview");
    }
  }

  function manageLocalStorage(previewObj) {
    setLocalStorage("dripSavedPreview", JSON.stringify(previewObj));
  }

  const handleSubmit = async (values) => {
    let payload = {
      ...values,
      leadIds: leadId,
      isSelectedAll: false,
      isFilter: hasNonEmptyValuesInObj(filterData),
      Filter: removeUndefined(filterData),
      ...fieldValues,
    };
    const res = await assignLeadTodrip(payload);
    const scheduleDripLeadsResponse = res?.data?.data?.scheduleDripLeads;
    try {
      if (isValidObject(scheduleDripLeadsResponse)) {
        const { type, message } = scheduleDripLeadsResponse;
        if (type === "success") {
          showSuccessMsg(message);
        } else if (type === "error") {
          showErrorMsg(message);
        }
      } else {
        showErrorMsg("Error occurred while Scheduling Drip.");
      }
    } catch (error) {
      showErrorMsg("Error occurred while Scheduling Drip.");
    }
    setIsOpen(false);
    formik.resetForm();
  };

  useEffect(() => {
    if (formik?.values?.dripId && node) {
      if (isValidObject(node?.data)) {
        if (node?.data?.isOpenAI) {
          generateOpenAIDripResponse();
        } else {
          generatDripPreview();
        }
      }
    }
  }, [formik?.values?.dripId, node]);

  function isRegenerateButtonDisable() {
    if (isValidObject(node?.data)) {
      if (node?.data?.isOpenAI) {
        return false;
      } else {
        return true;
      }
    }
  }

  function handleSkipNodes() {
    setDripForModifyFlow(formik?.values?.dripId);
    setIsOpen(false);
    setIsModifyDripFlowModal(true);
  }

  useEffect(() => {
    setSavedNodeTime({ ...fieldValues, id: node?.id });
  }, [fieldValues]);

  useEffect(() => {
    if (!isOpen) {
      formik?.resetForm();
      setNode({});
    }
  }, [isOpen]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[1111]"
          onClose={() => {
            // setIsOpen(false);
            // formik.resetForm();
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[800px] border-b-2 py-2 transform overflow-visible rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-5">
                  <div className="mt-1 border-b-2 py-2 sm:mt-0 flex justify-between items-center">
                    <div className="sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-800 flex justify-between items-center"
                      >
                        Assign Leads
                      </Dialog.Title>
                    </div>
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 "
                      onClick={() => {
                        setIsOpen(false);
                        formik?.resetForm();
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  <form
                    className="space-y-4 mt-3 max-h-[900px] overflow-visible p-1"
                    onSubmit={formik.handleSubmit}
                  >
                    <div className="overflow-visible">
                      <SelectField
                        formik={formik}
                        options={activeDripList}
                        name="dripId"
                        label="Select Drip"
                        isSearchable={false}
                        setFieldValue={formik?.setFieldValue}
                        onBlur={formik?.handleBlur}
                      />
                    </div>
                    <div className="transition-all">
                      {formik?.values?.dripId ? (
                        <>
                          <ScheduleTime
                            fieldValues={fieldValues}
                            setFieldValues={setFieldValues}
                            validateTimeChange={validateTimeChange}
                            manageSentAfterTime={manageSentAfterTime}
                            node={node}
                          />
                          <div className="mt-3 transition-all ">
                            <span className="flex font-medium">
                              Preview:{" "}
                              {isGenerateOpenAIDripPreviewLoading ||
                              isAddDripPreviewLoading ? (
                                <span>
                                  <label className="ml-2">
                                    Generating Preview
                                  </label>
                                  <span className="mt-0.5">
                                    <PulseLoader size={3} color="#000000" />
                                  </span>
                                </span>
                              ) : (
                                ""
                              )}
                            </span>
                            {!isGenerateOpenAIDripPreviewLoading &&
                            !isAddDripPreviewLoading ? (
                              <div>
                                {node?.type === "message" ? (
                                  <TextArea
                                    name="message"
                                    formik={formik}
                                    className="border-1 border-gray-300 bg-gray-100 placeholder-gray-700 "
                                    rows={6}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    autoComplete="message"
                                    placeholder="Preview Message"
                                    isNotRemoveExtraSpace={true}
                                  />
                                ) : (
                                  <div>
                                    {node?.type === "mail" && (
                                      <div className="my-3">
                                        <label className="text-sm font-medium text-semibold mb-3">
                                          Subject:{" "}
                                        </label>
                                        <div className="text-sm">
                                          <input
                                            type="text"
                                            id="subject"
                                            name="subject"
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-[#475361] focus:border-[#475361] sm:text-sm"
                                            onChange={formik.handleChange}
                                            onBlur={(e) => {
                                              const values = e.target.value;
                                              const removeSpace = values
                                                .replace(/\s+/g, " ")
                                                .trim();
                                              formik.handleChange(e);
                                              formik.setFieldValue(
                                                "subject",
                                                removeSpace
                                              );
                                            }}
                                            value={formik?.values?.subject}
                                            placeholder="Subject Template"
                                          />
                                        </div>
                                      </div>
                                    )}
                                    <div>
                                      {node?.type === "mail" && (
                                        <label className="text-sm font-medium text-semibold">
                                          Body:
                                        </label>
                                      )}
                                      <div className="mb-4">
                                        <CkEditor
                                          formik={formik}
                                          name={"message"}
                                          editorRef={quillRef}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {drip?.isSameFrequency ? (
                                  <div className="bg-blue-100 mb-1 border-l-4  border-blue-500 text-blue-700 p-2 rounded-md shadow-md m-1">
                                    <div className="flex items-center">
                                      <ExclamationCircleIcon className="h-5 w-5 text-blue-700 mr-1" />
                                      <p className="font-bold text-xs">Note:</p>
                                      <p className="ml-1 text-xs">
                                        Here is a preview of the first
                                        {node?.type === "message"
                                          ? " text"
                                          : " " + node?.type}{" "}
                                        which will be{" "}
                                        {node?.type === "task"
                                          ? " created"
                                          : " sent"}{" "}
                                        in {fieldValues?.time}{" "}
                                        {fieldValues?.type} and Drip will
                                        process other nodes every{" "}
                                        {drip?.freqTime} {drip?.freqType}
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <SpinnerButton
                        className="inline-flex w-full justify-center rounded-md btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                        title={"Assign"}
                        type={"submit"}
                        isDisable={
                          !formik?.values?.dripId ||
                          isAddDripPreviewLoading ||
                          isGenerateOpenAIDripPreviewLoading
                        }
                        loading={isAssignDripLoading}
                        toolTip="Continue"
                      />
                      <SpinnerButton
                        className="inline-flex w-full justify-center rounded-md btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                        title={"Skip Nodes"}
                        action={() => handleSkipNodes()}
                        type={"button"}
                        isDisable={
                          !formik?.values?.dripId ||
                          isAddDripPreviewLoading ||
                          isGenerateOpenAIDripPreviewLoading
                        }
                        toolTip="Skip Nodes"
                        loading={false}
                      />
                      {node?.data?.isOpenAI && formik?.values?.dripId ? (
                        <SpinnerButton
                          className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                          title={"Regenerate Preview"}
                          action={() => generateOpenAIDripResponse()}
                          type={"button"}
                          loading={false}
                          isDisable={
                            isRegenerateButtonDisable() ||
                            isGenerateOpenAIDripPreviewLoading
                          }
                        />
                      ) : (
                        <></>
                      )}
                      {formik?.values?.dripId ? (
                        <SpinnerButton
                          className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                          title={"See Drip Campaign"}
                          action={() =>
                            window.open(
                              `/drip-flow/${formik?.values?.dripId}`,
                              "_blank"
                            )
                          }
                          type={"button"}
                          loading={false}
                          isDisable={
                            isAddDripPreviewLoading ||
                            isGenerateOpenAIDripPreviewLoading
                          }
                        />
                      ) : (
                        <></>
                      )}
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => {
                          setIsOpen(false);
                          formik.resetForm();
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
