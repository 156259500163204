import { ClipLoader } from "react-spinners";

const TooltipContent = (props) => {
  const {
    dripTooltipInfo,
    isNextStepPreviewLodaing,
    isPreviewLoading,
    isGenerateOpenAIDripPreviewLoading,
    setIsRescheduleDripModal,
    generateOpenAIDripResponse,
    setIsOpenDripHoverEditModal,
    PulseLoader,
    handleSkipNodes = () => {},
    isSkipCurrentNodeLodaing,
  } = props;
  return (
    <div className="h-full">
      <div className="flex justify-center capitalize">
        {dripTooltipInfo?.type ? `Node type :  ${dripTooltipInfo?.type}` : ""}
      </div>
      <div className="flex justify-center">{dripTooltipInfo?.status}</div>
      <div className="flex justify-center capitalize">
        {dripTooltipInfo?.nodeId
          ? "Next: #" + dripTooltipInfo?.nodeId + " - "
          : ""}
        {dripTooltipInfo?.time}
      </div>
      {dripTooltipInfo?.dripSortOrder !== 4 &&
        dripTooltipInfo?.dripSortOrder !== 5 && (
          <>
            <div className="!text-wrap max-w-[600px]">
              {isNextStepPreviewLodaing ||
              isPreviewLoading ||
              !dripTooltipInfo?.preview ? (
                <div className="capitalize">
                  <hr className="m-2 border-white min-w-[100px]" />
                  <span className="flex">
                    Preview:{" "}
                    <span className="flex w-full">
                      <label className="ml-2">Generating Preview</label>
                      <span className="mt-0.5">
                        <PulseLoader size={3} color="#ffffff" />
                      </span>
                    </span>
                  </span>
                </div>
              ) : (
                dripTooltipInfo?.preview && (
                  <div className="max-w-[600px]">
                    <hr className="m-2 border-white min-w-[100px]" />
                    {isGenerateOpenAIDripPreviewLoading ? (
                      <span className="flex">
                        Preview:{" "}
                        <span className="flex w-full">
                          <label className="ml-2">Generating Preview</label>
                          <span className="mt-0.5">
                            <PulseLoader size={3} color="#ffffff" />
                          </span>
                        </span>
                      </span>
                    ) : (
                      <div>Preview:</div>
                    )}
                    <div>
                      {dripTooltipInfo?.type === "mail" && (
                        <div className="my-2">
                          Subject: <div> {dripTooltipInfo?.subject}</div>
                        </div>
                      )}
                      <div className="my-2">
                        {dripTooltipInfo?.type === "mail" && "Body:"}
                        <div
                          contentEditable={false}
                          className="text-justify p-2"
                          style={{ whiteSpace: "pre-wrap" }}
                          dangerouslySetInnerHTML={{
                            __html: dripTooltipInfo?.preview,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            <div className="flex justify-end mt-3 space-x-2">
              {isSkipCurrentNodeLodaing ? (
                <span className="px-2 flex items-center border border-white">
                  <ClipLoader size={20} color="#ffffff" />
                </span>
              ) : (
                <button
                  className="px-2 py-1 border border-white"
                  onClick={() => handleSkipNodes(true)}
                >
                  Skip Node
                </button>
              )}
              <div>
                <button
                  className="px-2 py-1 border border-white"
                  onClick={() => setIsRescheduleDripModal(true)}
                >
                  Reschedule Drip
                </button>
              </div>
              {dripTooltipInfo?.isOpenAI && dripTooltipInfo?.preview ? (
                <button
                  className="px-2 py-1 border border-white"
                  onClick={() => {
                    if (!isGenerateOpenAIDripPreviewLoading) {
                      generateOpenAIDripResponse({
                        dripId: dripTooltipInfo?.dripId,
                        nodeId: dripTooltipInfo?.nodeId,
                        templateType: dripTooltipInfo?.type,
                      });
                    }
                  }}
                >
                  Regenerate Preview
                </button>
              ) : (
                <></>
              )}
              {dripTooltipInfo?.preview && dripTooltipInfo?.type !== "task" ? (
                <button
                  className="px-2 py-1 border border-white"
                  onClick={() => setIsOpenDripHoverEditModal(true)}
                >
                  Edit Preview
                </button>
              ) : (
                <></>
              )}
            </div>
          </>
        )}
    </div>
  );
};

export default TooltipContent;
