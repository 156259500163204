import { Dialog, Popover, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  InformationCircleIcon,
  PhoneArrowDownLeftIcon,
  PhoneArrowUpRightIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import InputField from "Components/Common/Fields/InputField";
import Skeleton from "Components/Common/Fields/Skeleton";
import SpinnerButton from "Components/Common/Fields/SpinnerButton";
import TextArea from "Components/Common/Fields/TextArea";
import useEscapeClose from "Components/Common/Hooks/useEscapeClose";
import CkEditor from "Components/Common/TextEditor/CkEditor";
import { useFormik } from "formik";
import {
  convertCapitalize,
  getOrdinalSuffix,
  isValidArray,
  isValidObject,
  replaceNewlinesWithBR,
  showDateAndTimeFormat,
  showErrorMsg,
  showSuccessMsg,
} from "Modules/util";
import { Fragment, Suspense, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  useGenerateOpenAIDripResponseMutation,
  useGetLeadQuery,
  useNextDripPreviewMutation,
} from "Redux/Leads/lead";
import { useGetSMSByLeadIdQuery } from "Redux/Leads/SMS/sms";
import { useLeadDripMessageSaveMutation } from "Redux/pendingMessages/pendingMessagesQuery";
import * as Yup from "yup";

export default function ActionModal(props) {
  // Props
  const { isOpen, isSetOpen, leadData } = props;

  const smsContainerRef = useRef(null);
  const quillRef = useRef(null);
  const [smsData, setSMSData] = useState();

  // Fetching lead data using a GraphQL query
  const { data: getLead, isLoading } = useGetLeadQuery(
    { _id: leadData?.leadId },

    {
      skip: !leadData?.leadId ? true : false,
    }
  );

  const { data: getSMSByLeadId, isLoading: isSMSByLeadIdLoading } =
    useGetSMSByLeadIdQuery(
      { _id: leadData?.leadId },
      { skip: !leadData?.leadId ? true : false }
    );

  // AI Template API
  const [
    generateOpenAIDripPreview,
    { isLoading: isGenerateOpenAIDripPreviewLoading },
  ] = useGenerateOpenAIDripResponseMutation();
  useEscapeClose(isSetOpen); // Custom hook to close a modal when the Escape key is pressed.
  // API for Next node preview
  const [nextStepPreview, { isLoading: isNextStepPreviewLodaing }] =
    useNextDripPreviewMutation();

  const [leadDripMessageSave, { isLoading: isLeadDripMessageSaveLoading }] =
    useLeadDripMessageSaveMutation();

  // Extracting lead information
  let lead = isValidArray(getLead?.data?.lead?.response)
    ? getLead?.data?.lead?.response?.[0]
    : [];

  const leadInfo = isValidObject(lead?.leadResponse?.[0])
    ? lead?.leadResponse?.[0]
    : [];

  // Effect to scroll to the bottom when SMS are updated
  useEffect(() => {
    // Added minor delay which gives times to load a Dom
    const scrollTimeout = setTimeout(() => {
      if (smsContainerRef?.current) {
        smsContainerRef.current.scrollTop =
          smsContainerRef.current.scrollHeight;
      }
    }, 100);

    return () => clearTimeout(scrollTimeout);
  }, [lead?.smsResponse]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      message: leadData?.message,
      mail: replaceNewlinesWithBR(leadData?.message),
      subject: leadData?.subject,
    },
    validationSchema: Yup.object().shape({
      message:
        leadData.nodeType === "message" &&
        Yup.string().required("Message Is Required"),
      mail:
        leadData.nodeType === "mail" &&
        Yup.string().required("Mail Is Required"),
    }),
    onSubmit: async (values) => {
      savePreviewMessage(values);
    },
  });

  async function generateOpenAIDripResponse() {
    let payload = {
      dripId: leadData?.dripId,
      leadId: leadData?.leadId,
      nodeId: leadData?.nodeId,
      nodeType: leadData?.nodeType,
      isSuggestedDrip: false,
    };

    const res = await generateOpenAIDripPreview(payload);
    const openAIDripPreview = res?.data?.data?.generateOpenAIDripResponse;
    try {
      if (isValidObject(openAIDripPreview)) {
        const { type, message, response, subject } = openAIDripPreview;
        if (type === "success") {
          if (leadData?.nodeType === "message") {
            formik?.setFieldValue("message", response);
          } else {
            formik?.setFieldValue("mail", replaceNewlinesWithBR(response));
            formik?.setFieldValue("subject", subject);
          }
          showSuccessMsg(message);
        } else if (type === "error") {
          showErrorMsg(message);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      } else {
        showErrorMsg("Error occurred while generating preview!");
      }
    } catch (error) {
      showErrorMsg("Error occurred while generating preview");
    }
  }

  // function of get next step drip preview
  async function handleGetNextStepDripPreview() {
    let payload = {
      dripId: leadData?.dripId,
      leadId: leadData?.leadId,
    };

    try {
      const res = await nextStepPreview(payload);
      const nextDripPreview = res?.data?.data?.nextDripPreview;
      if (isValidObject(nextDripPreview)) {
        const { type, message, preview } = nextDripPreview;
        if (type === "success") {
          showSuccessMsg(message);
          if (leadData?.nodeType === "message") {
            formik?.setFieldValue("message", preview);
          } else {
            formik?.setFieldValue("mail", replaceNewlinesWithBR(preview));
          }
        } else if (type === "error") {
          showErrorMsg(message);
        }
      } else {
        showErrorMsg("Error occurred while generating preview!");
      }
    } catch (error) {
      showErrorMsg("Error occurred while generating preview");
    }
  }

  async function savePreviewMessage(values) {
    let payload = {
      dripId: leadData?.dripId,
      leadId: leadData?.leadId,
      message: leadData.nodeType === "mail" ? values?.mail : values?.message,
      ...(leadData?.nodeType === "mail" ? { subject: values?.subject } : {}),
    };

    try {
      const res = await leadDripMessageSave(payload);
      const dripPreviewSave = res?.data?.data?.leadDripMessageSave;
      if (isValidObject(dripPreviewSave)) {
        const { type, message } = dripPreviewSave;
        if (type === "success") {
          showSuccessMsg(message);
        } else if (type === "error") {
          showErrorMsg(message);
        }
      } else {
        showErrorMsg("Error occurred while saving preview!");
      }
    } catch (error) {
      showErrorMsg("Error occurred while saving preview");
    }
  }

  useEffect(() => {
    if (isOpen && !leadData?.message) {
      if (leadData?.isOpenAI) {
        generateOpenAIDripResponse();
      } else {
        handleGetNextStepDripPreview();
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (leadData?.nodeType === "mail") {
      if (formik?.values?.mail?.trim() == "<p><br></p>") {
        formik.setFieldError("mail", "Please Enter Mail");
      }
    }
  }, [formik?.values?.mail]);

  useEffect(() => {
    let smsData = getSMSByLeadId?.data?.getSMSByLeadId?.smsResponse;
    if (!isSMSByLeadIdLoading) {
      if (isValidArray(smsData)) {
        setSMSData(smsData);
      } else {
        setSMSData([]);
      }
    }
  }, [getSMSByLeadId]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[1111]" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full border-b-2 py-2 max-w-5xl transform overflow-visible rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-5 ">
                <div className="mt-1 border-b-2 py-2 sm:mt-0 flex justify-between items-center">
                  <div className="sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-800 flex justify-between items-center"
                    >
                      {leadData?.leadName ?? "Preview Action"}
                    </Dialog.Title>
                  </div>
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 "
                    onClick={() => {
                      formik.resetForm();
                      isSetOpen(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {leadData?.nodeType === "message" ? (
                  <div className="mt-4">
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      Message History
                    </label>

                    <div
                      className={`text-sm  min-h-[200px] max-h-[310px] overflow-y-auto bg-white border-2 border-gray-300 rounded-md mt-2 relative`}
                      ref={smsContainerRef}
                    >
                      {isLoading ? (
                        <Skeleton height={"60px"} counter={3} />
                      ) : (
                        <>
                          <Suspense
                            fallback={<Skeleton height={"60px"} counter={3} />}
                          >
                            {isValidArray(smsData) ? (
                              smsData?.map((sms, index) => (
                                <div
                                  key={index}
                                  className={`border m-2 px-4 py-2 shadow-md rounded-lg ${
                                    sms?.status === "received"
                                      ? "mr-24 my-3 bg-gray-50"
                                      : "ml-24 bg-orange-50"
                                  }`}
                                >
                                  <div className="flex w-full justify-between">
                                    <div className="flex items-center mb-2">
                                      <h2 className="font-semibold flex text-gray-600">
                                        <span className="mr-2">From:</span>
                                        <span className="flex  bg-gray-200 px-1 rounded-md">
                                          <PhoneArrowUpRightIcon className="w-4 text-orange-400 mr-1" />
                                          {sms?.from}
                                        </span>
                                        <span className="mx-2">To:</span>
                                        <span className="flex  bg-gray-200 px-1 rounded-md">
                                          <PhoneArrowDownLeftIcon className="w-4 text-orange-400 mr-1" />
                                          {sms?.to || (
                                            <span className=" w-24 text-center bg-gray-200 p-1 rounded-md">
                                              {"-"}
                                            </span>
                                          )}
                                        </span>
                                      </h2>
                                    </div>
                                    <p className="text-gray-700">
                                      {showDateAndTimeFormat(sms?.createdAt)}
                                    </p>
                                  </div>

                                  <div className="flex w-full justify-between">
                                    <p className="text-gray-700">
                                      <b className="text-gray-600">Message:</b>{" "}
                                      {sms?.message}
                                    </p>
                                    <div className="flex flex-col items-end mt-auto">
                                      {sms?.status !== "received" ? (
                                        <p className="item mb-[2px] text-xs font-semibold text-gray-600 bg-gray-200 px-2 rounded-md flex">
                                          {sms?.type === "byDrip" ||
                                          sms?.type === "byOpenAI"
                                            ? "By Drip"
                                            : sms?.type === "direct"
                                            ? "By Direct"
                                            : sms?.type === "import"
                                            ? "Import"
                                            : sms?.type === "autoFollowUp"
                                            ? "Auto Follow-up"
                                            : ""}
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                      <p
                                        className={`flex ${
                                          sms?.status === "failed" ||
                                          sms?.status === "undelivered"
                                            ? "text-red-500"
                                            : "text-green-600"
                                        }`}
                                      >
                                        {sms?.status === "undelivered" ? (
                                          <Popover className="relative">
                                            <Popover.Button>
                                              <InformationCircleIcon className="text-red-500 hover:text-red-600 w-5" />
                                            </Popover.Button>
                                            <Popover.Panel className="absolute -top-12 -left-80 z-50 bg-white p-3 shadow-lg rounded-md w-[315px]">
                                              <p className="text-gray-700 max-h-52 overflow-y-auto">
                                                {sms?.errorMessage + " "}
                                              </p>

                                              <Link
                                                className="text-red-500 hover:underline hover:text-red-600"
                                                target="_blank"
                                                to={sms?.errorURL}
                                              >
                                                Click here to learn more about
                                                ERROR:
                                                {sms?.errorCode && (
                                                  <> {sms.errorCode}</>
                                                )}
                                              </Link>
                                            </Popover.Panel>
                                          </Popover>
                                        ) : sms?.status === "failed" ? (
                                          <XCircleIcon className="text-red-500 w-5 h-5" />
                                        ) : (
                                          <CheckCircleIcon className="text-green-600 w-5 h-5" />
                                        )}
                                        <span className="ml-2">
                                          {sms?.status &&
                                            convertCapitalize(sms?.status)}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="p-4 text-gray-600 text-sm h-full flex justify-center ">
                                No SMS history available.
                              </div>
                            )}
                          </Suspense>
                        </>
                      )}
                      <div className="flex justify-center text-sm mt-4 mb-3">
                        {leadInfo?.isFollowUp && (
                          <span className="bg-orange-200 p-2 rounded text-gray-600 mx-8">
                            <b>Auto Follow-up: </b>
                            {getOrdinalSuffix(leadInfo?.followUpCount + 1)} Auto
                            Follow-up on{" "}
                            {showDateAndTimeFormat(
                              leadInfo?.followUpScheduledDateTime
                            )}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="mt-4">
                  {leadData?.nodeType === "message" ? (
                    <TextArea
                      id="message"
                      label={
                        leadData?.nodeType === "mail"
                          ? "Mail Preview"
                          : "Message Preview"
                      }
                      name="message"
                      formik={formik}
                      className="border-1 border-gray-300 bg-gray-100 placeholder-gray-700 "
                      rows={6}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      autoComplete="message"
                      placeholder="Preview Message"
                      isNotRemoveExtraSpace={true}
                    />
                  ) : (
                    <div>
                      <div className="mb-4">
                        <InputField
                          formik={formik}
                          label="Mail Subject Preview"
                          id="subject"
                          name="subject"
                          type="text"
                          placeholder="Enter subject"
                        />
                      </div>
                      <label className="block text-sm font-medium leading-6 text-gray-900 pb-2">
                        Mail Body Preview
                      </label>
                      <div className="mb-4">
                        <CkEditor
                          formik={formik}
                          name={"mail"}
                          editorRef={quillRef}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex justify-end">
                  {leadData?.isOpenAI ? (
                    <SpinnerButton
                      className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                      title={"Regenerate Preview"}
                      action={() => {
                        if (!isGenerateOpenAIDripPreviewLoading) {
                          generateOpenAIDripResponse();
                        }
                      }}
                      type={"button"}
                      loading={isGenerateOpenAIDripPreviewLoading}
                      isDisable={isGenerateOpenAIDripPreviewLoading}
                      toolTip={"Regenerate Preview"}
                    />
                  ) : (
                    <></>
                  )}
                  <SpinnerButton
                    className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                    title={"Save Preview"}
                    action={() => {
                      formik.handleSubmit();
                      isSetOpen(false);
                    }}
                    type={"submit"}
                    loading={isLeadDripMessageSaveLoading}
                    isDisable={
                      isGenerateOpenAIDripPreviewLoading ||
                      isNextStepPreviewLodaing ||
                      isValidObject(formik.errors) ||
                      formik?.values?.mail === "<p><br></p>"
                    }
                    toolTip={"Save Preview"}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
          <div></div>
        </div>
      </Dialog>
    </Transition>
  );
}
