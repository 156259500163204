import CustomPhoneInput from "Components/Common/Fields/CustomPhoneInput";
import InputField from "Components/Common/Fields/InputField";
import PasswordField from "Components/Common/Fields/PasswordField";
import SpinnerButton from "Components/Common/Fields/SpinnerButton";
import TextField from "Components/Common/Fields/TextField";
import { phoneFormat } from "Components/Common/formatter";
import {
  deleteAllCookies,
  flushLocalStorage,
  isValidObject,
  setAuthToken,
  setLocalStorage,
  setUser,
  setUserId,
  showErrorMsg,
  showSuccessMsg,
} from "Modules/util";
import { useAccountSetupMutation } from "Redux/Front/userAuth";
import { useLogoutMutation } from "Redux/auth/userAuth";
import { useUserMutation } from "Redux/user/user";
import { currentUser, refetchCurrentUser, user } from "Redux/userSlice";
import { useFormik } from "formik";
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useCheckUserQuery } from "Redux/user/user";
import * as Yup from "yup";

export default function InvitedUser() {
  const [searchParams] = useSearchParams(); // Hook to get search params from URL
  const [accountSetup, { isLoading }] = useAccountSetupMutation(); // Hook to handle account setup mutation
  const [logout] = useLogoutMutation(); // Hook to handle logout mutation
  const [getUser] = useUserMutation(); // Hook to fetch user data
  const navigate = useNavigate(); // Hook to handle navigation
  const dispatch = useDispatch(); // Redux dispatch hook
  const token = localStorage?.getItem("AUTH_TOKEN"); // Retrieve the JWT token from local storage

  // Extract parameters from URL
  const paramEmail = searchParams.get("email");
  const paramIsLender = searchParams.get("isLender");
  const paramIsAgent = searchParams.get("isAgent");
  const paramUserId = searchParams.get("id");

  const { data: userDeatils, isLoading: isUserDeatilsLoading } =
    useCheckUserQuery({ _id: paramUserId });

  useEffect(() => {
    const userInfo = userDeatils?.data?.checkUserAccount?.response;
    if (!isUserDeatilsLoading) {
      if (isValidObject(userInfo)) {
        if (userInfo?.isAccountSetUP) {
          navigate("/");
          showErrorMsg("Your Account Is Already Setup");
        }
      }
    }
  }, [userDeatils]);

  // Function to handle fetching and setting user data
  const handleUserData = async (userId) => {
    if (!userId) {
      return null;
    }
    const res = await getUser(userId); // Await the result of the getUser mutation with the provided userId
    const userRes = res?.data?.data?.user?.response; // Extract the user response data from the result
    // Check if the user response object is valid
    if (isValidObject(userRes)) {
      dispatch(currentUser(userRes)); // Dispatch an action to update the current user state in the Redux store
      setUser(userRes?.firstName + " " + userRes?.lastName); // Set the user name in local storage
      setLocalStorage(
        "isPhoneNumber",
        userRes?.agentTwilioNumber ? true : false
      ); // Set the local storage key 'isPhoneNumber' based on the presence of an agent Twilio number
      setUserId(userRes?._id); // Set the userId in local storage
      dispatch(refetchCurrentUser(true));
      navigate("/dashboard"); // Navigate the user to the dashboard page
    }
  };

  // Initialize Formik for form handling
  const formik = useFormik({
    enableReinitialize: true, // Allows form to reinitialize with new initial values
    initialValues: {
      id: paramUserId,
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      phone: "",
      financialInstitute: "",
      companyName: "",
    },
    // Define validation schema using Yup
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First Name Is Required"),
      lastName: Yup.string().required("Last Name Is Required"),
      password: Yup.string()
        .min(6, "Password Must Be At Least 6 characters")
        .required("Password Is Required"),
      confirmPassword: Yup.string()
        .oneOf(
          [Yup.ref("password"), null],
          "Password and Confirm Password must match."
        )
        .required("Confirm Password is required."),
      phone: Yup.string()
        .matches(phoneFormat, "Please Enter Valid Phone Number")
        .required("Phone is Required."),
      financialInstitute:
        paramIsLender === "true" &&
        Yup.string().required("Financial Institute Is Required"),
      companyName:
        paramIsAgent === "true" &&
        Yup.string().required("Company Name Is Required"),
    }),
    onSubmit: (values) => {
      // Remove confirm password from payload
      // Trigger account setup mutation
      delete values?.confirmPassword;
      const dripFromEmail = `${values?.firstName?.toLocaleLowerCase()}@bryanhossack.com`;
      const individualFromEmail = `${values?.firstName?.toLocaleLowerCase()}@bryanhossack.com`;
      const bulkFromEmail = `${values?.firstName?.toLocaleLowerCase()}@bryanhossack.com`;
      accountSetup({
        ...values,
        dripFromEmail,
        individualFromEmail,
        bulkFromEmail,
      })
        .then((response) => {
          const resData = response?.data?.data?.userAccountSetup; // Extract the user account setup data from the response
          if (resData?.type === "success") {
            const decoded = jwtDecode(resData?.token); // Decode the JWT token to get user information
            dispatch(
              user({
                id: decoded?.id,
                permission: decoded?.permission,
              })
            ); // Dispatch an action to update the user state in the Redux store
            setAuthToken(resData?.token); // Set the authentication token in local storage or a global state
            showSuccessMsg(resData?.message); // Display a success message to the user
            handleUserData(decoded?.id); // Handle user data by calling the handleUserData function with the decoded user ID
          } else if (resData?.type === "error") {
            showErrorMsg(resData?.message); // If the response type indicates an error, display an error message to the user
          }
        })
        .catch((error) => showErrorMsg("Error occurred while Adding User."));
    },
  });

  // Trigger logout mutation
  const handleLogout = () => {
    logout()
      .then((response) => {
        if (response?.data?.data?.logoutUser.type === "success") {
          showSuccessMsg(response?.data?.data?.logoutUser?.message);
        } else if (response?.data?.errors) {
          showErrorMsg(response?.data?.errors[0]?.message);
        }
      })
      .catch((error) => showErrorMsg("Error occurred while Logout."))
      .finally(() => {
        flushLocalStorage(); // Clear local storage
        deleteAllCookies(); // Delete all cookies
      });
  };

  useEffect(() => {
    if (paramUserId) {
      if (token) {
        handleLogout(); // Log out if there's a token and userId
      }
    } else {
      navigate("/"); // Navigate to home if there's no userId
    }
  }, []);

  return (
    <div className="flex  flex-1 w-[50%] flex-col justify-center  sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div className="mx-auto w-full max-w-lg  border border-1 border-white px-16 py-6 bg-neutral-50 rounded-xl shadow-md shadow-gray-400">
        <span className="mt-8 text-3xl font-bold leading-9 tracking-tight text-gray-800">
          Account Setup
        </span>
        <div className="mt-8">
          <div>
            <form className="space-y-4 mb-4" onSubmit={formik.handleSubmit}>
              <InputField
                formik={formik}
                id="firstName"
                name="firstName"
                type="text"
                label="First Name"
                onChange={formik.handleChange}
                autoComplete="name"
                placeholder="Enter First Name"
                className=""
              />
              <InputField
                formik={formik}
                id="lastName"
                name="lastName"
                type="text"
                label="Last Name"
                onChange={formik.handleChange}
                autoComplete="name"
                placeholder="Enter Last Name"
                className=""
              />
              <TextField
                formik={formik}
                label="Email"
                placeholder="Email"
                value={paramEmail}
                id="email"
                name="email"
              />
              <CustomPhoneInput
                formik={formik}
                values={formik?.values}
                label="Phone"
                name="phone"
              />
              {paramIsLender === "true" && (
                <InputField
                  formik={formik}
                  id="financialInstitute"
                  name="financialInstitute"
                  type="text"
                  label="Financial Institute"
                  onChange={formik.handleChange}
                  autoComplete="financialInstitute"
                  placeholder="Enter Financial Institute"
                  className=""
                />
              )}
              {paramIsAgent === "true" && (
                <InputField
                  formik={formik}
                  id="companyName"
                  name="companyName"
                  type="text"
                  label="Company Name"
                  onChange={formik.handleChange}
                  autoComplete="companyName"
                  placeholder="Enter Company Name"
                  className=""
                />
              )}
              <PasswordField
                formik={formik}
                id="password"
                name="password"
                type="password"
                label="Password"
                onChange={formik.handleChange}
                autoComplete="current-password"
                placeholder="Enter Password"
                className=""
              />
              <PasswordField
                formik={formik}
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Enter Confirm Password"
                type="text"
                label="Confirm Password"
              />
              <SpinnerButton
                className="!mt-8 rounded"
                title={"Submit"}
                action={() => {}}
                type={"submit"}
                isDisable={false}
                loading={isLoading}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
