import Loading from "Components/Common/Loading";
import ShowPageTitle from "Components/Common/ShowPageTitle";
import {
  getLocalStorage,
  isPermission,
  isValidArray,
  isValidObject,
  showErrorMsg,
  showSuccessMsg,
} from "Modules/util";
import { leadDetailsValidationForm } from "Modules/validation";
import { useListCategoryQuery } from "Redux/Leads/Category";
import {
  useEditLeadDetailsMutation,
  useGetLeadQuery,
  useGetTagListQuery,
  useLeadNavigationMutation,
} from "Redux/Leads/lead";
import { setBreadcrumb } from "Redux/themeSlice";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
  // useSearchParams,
} from "react-router-dom/dist";
import { getCategoryList, getTagsList } from "../assetData";
import AddDeatilsModal from "./AddDeatilsModal";
import CallLogsHistory from "./CallLogsHistory/CallLogsHistory";
import { Box } from "./DND/Box";
import { Container } from "./DND/Container";
import Drip from "./Drip/Drip";
import EmailHistory from "./EmailHistory/EmailHistory";
import History from "./History/History";
import LeadActivity from "./LeadActivity/LeadActivity";
import LeadNavigation from "./LeadNavigation";
import LeadStory from "./LeadStory/LeadStory";
import Note from "./Notes/Note";
import LeadProfile from "./Profile/LeadProfile";
import PropertySearches from "./PropertySearches/PropertySearches";
import SMS from "./SMS/SMS";
import Tasks from "./Tasks/Tasks";
import { useAgentAndAgentManagerListQuery } from "Redux/user/user";
// LeadDetails component receives leadId, isModal, handleModalClick, and taskRefetch as props.

const LeadDetails = ({
  leadId,
  isModal,
  handleModalClick,
  setCompletedTaskList,
  completedTaskList,
  isCompleteTaskInProcess,
  setIsCompleteTaskInProcess,
  isOpen,
  deletedTask = null,
  setDeletedTask = () => {},
  setIsOpenNestModal = () => {},
  handleCompletedTasks = () => {},
}) => {
  const dispatch = useDispatch(); // Redux dispatch function
  const { id } = useParams(); // Extracting the 'id' parameter from the URL
  const [searchParams] = useSearchParams();
  const [filterData, setFilterData] = useState({});
  const [isOpenAddDeatilsModal, setIsOpenAddDeatilsModal] = useState(false);
  const [isOpenAddBirthdayModal, setIsOpenAddBirthdayModal] = useState(false);

  const {
    data: leadData,
    isSuccess: leadIsSuccess,
    isFetching,
    isError,
    isUninitialized,
    refetch: leadRefetch,
  } = useGetLeadQuery({
    _id: isModal ? leadId : id,
    ...filterData,
  }); // Fetching lead data using a query hook
  const [editLead, { isLoading: isLeadEditLoading }] =
    useEditLeadDetailsMutation();
  const [leadNavigation] = useLeadNavigationMutation();

  const { data: getTags, isSuccess: isTagsSuccess } = useGetTagListQuery({
    search: "",
  }); // Fetching tags data using a query hook
  const { data: getAllCategory, isSuccess: isCategorySuccess } =
    useListCategoryQuery({
      search: "",
    }); // Fetching category data using a query hook

  const {
    data: agentAndAgentManagerList,
    isSuccess: agentAndAgentManagerListIsSuccess,
    isLoading: isAgentAndAgentManagerListLoading,
  } = useAgentAndAgentManagerListQuery(); // Fetching Agent And AgentManager List data using a query hook

  // State variables to store agents, lenders, categories, and tags
  const [agentsWithPhone, setAgentsWithPhone] = useState([]);
  const [categorys, setCategorys] = useState([]);
  const [tags, setTags] = useState([]);
  const [isEditableOpen, setIsEditableOpen] = useState("");
  const agentList = useSelector((s) => s?.option?.agent);
  const lenderList = useSelector((s) => s?.option?.lender);
  const sourceList = useSelector((s) => s?.option?.source);
  const [handleNavigationButton, setNavigationButton] = useState({
    type: "",
    isDisable: false,
  });
  const notesContainerRef = useRef(null);
  const smsboxContainerRef = useRef(null);
  const { state, pathname } = useLocation();
  const [sortingWise, setSortingWise] = useState([]);
  const [dropChange, setDropChange] = useState(false);
  let isLeadListModal = pathname === "/leads";

  const userPermissions = useSelector((state) => state?.user?.permission); // Extracting user permissions from Redux state
  let isEditPermisstion = isPermission(userPermissions, "edit_leads"); // Checking if the user has the 'edit_leads' permission

  const navigate = useNavigate();
  const lead =
    leadIsSuccess && isValidArray(leadData?.data?.lead?.response)
      ? leadData?.data?.lead?.response[0]
      : {};
  const closedCategoryId = getAllCategory?.data?.listCategory?.response?.find(
    (item) => item?.close
  )?._id;
  const paramSearch = searchParams.get("search");

  // Function to refetch lead data
  const refetch = () => {
    if (!isUninitialized) {
      leadRefetch();
    }
    if (isModal) {
      // taskRefetch();
    }
  };

  // Function to handle lead submission
  async function editPhoneEmail(editFiled) {
    try {
      let res = await editLead({
        _id: lead?.leadResponse?.[0]?._id,
        ...editFiled,
      });
      const responseData = res?.data?.data?.editLead;
      if (responseData) {
        if (responseData?.type === "success") {
          showSuccessMsg(responseData?.message);
        } else if (responseData?.type === "error") {
          showErrorMsg(responseData?.message);
          formik?.resetForm();
          refetch();
        }
      }
      if (responseData?.type !== "success") {
        formik?.resetForm();
      }
    } catch (error) {
      formik?.resetForm();
      showErrorMsg("Error While Edit Lead submission.");
    } finally {
      refetch();
    }
  }
  // Set breadcrumb when lead response is available

  const tagsId = lead?.tagResponse?.map((tag) => tag?._id);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: isValidArray(lead?.leadResponse)
        ? lead?.leadResponse?.[0]?.firstName
        : "",
      lastName: isValidArray(lead?.leadResponse)
        ? lead?.leadResponse?.[0]?.lastName
        : "",
      email:
        isValidArray(lead?.leadResponse) && lead?.leadResponse?.[0]?.email?.[0]
          ? [lead?.leadResponse?.[0]?.email?.[0]]
          : [],
      phone: isValidArray(lead?.leadResponse)
        ? lead?.leadResponse?.[0]?.phone
        : [],
      address: isValidArray(lead?.leadResponse)
        ? lead?.leadResponse?.[0]?.address
        : "",
      agent: isValidArray(lead?.agentResponse)
        ? lead?.agentResponse[0]?._id
        : "",
      lender: isValidArray(lead?.lenderResponse)
        ? lead?.lenderResponse[0]?._id
        : "",

      category: isValidArray(lead?.categoryResponse)
        ? lead?.categoryResponse[0]?._id
        : "",
      tags: isValidArray(lead?.tagResponse) ? tagsId : [],
      leadType: isValidArray(lead?.leadResponse)
        ? lead?.leadResponse[0]?.leadType
        : "",
      description: isValidArray(lead?.leadResponse)
        ? lead?.leadResponse[0]?.description
        : "",
      source: isValidArray(lead?.leadResponse)
        ? lead?.leadResponse[0]?.source
        : "",
      registerDate: isValidArray(lead?.leadResponse)
        ? lead?.leadResponse?.[0]?.registerDate
        : "",
    },
    validationSchema: leadDetailsValidationForm(),
    onSubmit: async (values) => {
      let payload = {
        _id: lead?.leadResponse?.[0]?._id,
        ...values,
        closeDate:
          values?.category === closedCategoryId ? values?.closeDate : null,
      };
      try {
        let res = await editLead(payload);
        const responseData = res?.data?.data?.editLead;
        if (responseData) {
          if (responseData?.type === "success") {
            showSuccessMsg(responseData?.message);
          } else if (responseData?.type === "error") {
            showErrorMsg(responseData?.message);
            formik.resetForm();
            refetch();
          }
        }
        if (responseData?.type !== "success") {
          formik?.resetForm();
        }
      } catch (error) {
        formik.resetForm();
        showErrorMsg("Error While Edit Lead submission.");
      } finally {
        refetch();
        setIsOpenAddDeatilsModal(false);
      }
    },
  });

  useEffect(() => {
    if (isValidArray(lead?.leadResponse) && !isModal) {
      dispatch(
        setBreadcrumb([
          {
            title: "Lead",
            path: "/leads",
          },
          {
            title: "Details",
            path: `/leads/detail/${
              isValidArray(lead?.leadResponse) ? lead?.leadResponse[0]?._id : ""
            }`,
          },
          {
            title: `${
              isValidArray(lead?.leadResponse)
                ? lead?.leadResponse[0]?.firstName +
                  " " +
                  lead?.leadResponse[0]?.lastName
                : ""
            }`,
            path: `/leads/detail/${
              isValidArray(lead?.leadResponse) ? lead?.leadResponse[0]?._id : ""
            }`,
          },
        ])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead?.leadResponse]);

  // Effect to update tags when the tags data is successfully fetched
  useEffect(() => {
    if (isTagsSuccess) {
      setTags(getTagsList(getTags));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTagsSuccess]);
  // Effect to update agents and lenders when user data is successfully fetched
  useEffect(() => {
    if (!isAgentAndAgentManagerListLoading) {
      let agents = agentAndAgentManagerListIsSuccess
        ? agentAndAgentManagerList?.data?.AgentAndAgentManager?.response
        : [];

      if (isValidArray(agents)) {
        let filtertedAgentsWithCallerId = agents
          ?.filter((agent) => agent?.agentTwilioNumber)
          .map((agent) => {
            return {
              label: `(${agent?.name}) - ${agent?.agentTwilioNumber}`,
              value: agent?.agentTwilioNumber,
            };
          });
        setAgentsWithPhone(filtertedAgentsWithCallerId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentAndAgentManagerList]);

  // Effect to update categories when category data is successfully fetched
  useEffect(() => {
    if (isCategorySuccess) {
      setCategorys(getCategoryList(getAllCategory));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCategorySuccess]);

  // Effect to navigate to "/leads" when there is an error fetching data
  useEffect(() => {
    if (isError && !isModal) {
      navigate("/leads");
    }
    if (leadIsSuccess && !isValidArray(leadData?.data?.lead?.response)) {
      showErrorMsg("Lead Not Found");
      navigate("/leads");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, leadIsSuccess]);

  useEffect(() => {
    if (state?.target === "notes" || state?.target === "sms") {
      setTimeout(() => {
        if (state?.target === "notes") {
          if (notesContainerRef.current) {
            notesContainerRef.current.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        }

        if (state?.target === "sms") {
          if (smsboxContainerRef.current) {
            smsboxContainerRef.current.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        }
        if (isValidArray(lead?.leadResponse)) {
          navigate("");
        }
      }, 600);
    }
  }, [lead?.leadResponse, state?.target]);

  function CreateFilterData() {
    const filterData = {
      search: paramSearch,
    };
    return filterData;
  }

  const handleLeadNavigation = async (moveType) => {
    const filterData = CreateFilterData();
    const obj = {
      _id: id,
      buttonType: moveType,
      ...filterData,
    };
    const response = await leadNavigation(obj);

    if (
      isValidObject(response?.data?.data?.leadDetailsBasedOnFilter?.response)
    ) {
      if (
        isValidArray(
          response?.data?.data?.leadDetailsBasedOnFilter?.response?.leadResponse
        )
      ) {
        const nextLeadId =
          response?.data?.data?.leadDetailsBasedOnFilter?.response
            ?.leadResponse[0]?._id ?? 0;
        if (!!nextLeadId) {
          if (isLeadListModal) {
            navigate("?lead=" + nextLeadId);
          } else {
            navigate("/leads/detail/" + nextLeadId);
          }
          setNavigationButton({ type: moveType, isDisable: false });
        }
      } else {
        setNavigationButton({ type: moveType, isDisable: true });
      }
    }
  };

  const LeadSegments = [
    ...(isPermission(userPermissions, "call_logs_history")
      ? [
          {
            container: 1,
            component: CallLogsHistory,
            name: "CallLogsHistory",
            permission: "call_logs_history",
            props: {
              refetch: refetch,
              lead: lead,
              setIsOpenNestModal: setIsOpenNestModal,
            },
          },
        ]
      : []),

    ...(isPermission(userPermissions, "sms")
      ? [
          {
            container: 2,
            component: SMS,
            name: "SMS",
            permission: "sms",
            props: {
              lead: lead,
              filterData: filterData,
              smsboxContainerRef: smsboxContainerRef,
              setIsOpenNestModal: setIsOpenNestModal,
            },
          },
        ]
      : []),

    ...(isPermission(userPermissions, "email_history")
      ? [
          {
            container: 3,
            component: EmailHistory,
            name: "EmailHistory",
            permission: "email_history",
            props: { lead: lead },
          },
        ]
      : []),

    ...(isPermission(userPermissions, "tasks")
      ? [
          {
            container: 4,
            component: Tasks,
            name: "Tasks",
            permission: "tasks",
            props: {
              lead: lead,
              refetchLead: refetch,
              setCompletedTaskList: setCompletedTaskList,
              handleCompletedTasks: handleCompletedTasks,
              completedTaskList: completedTaskList,
              isModal: isModal,
              isCompleteTaskInProcess: isCompleteTaskInProcess,
              setIsCompleteTaskInProcess: setIsCompleteTaskInProcess,
              isOpen: isOpen,
              deletedTask: deletedTask,
              setDeletedTask: setDeletedTask,
              agents:
                agentAndAgentManagerList?.data?.AgentAndAgentManager
                  ?.response ?? [],
              setIsOpenNestModal: setIsOpenNestModal,
            },
          },
        ]
      : []),

    ...(isPermission(userPermissions, "property_searches")
      ? [
          {
            container: 5,
            component: PropertySearches,
            name: "PropertySearches",
            permission: "property_searches",
            props: {
              lead: lead,
            },
          },
        ]
      : []),

    ...(isPermission(userPermissions, "drip")
      ? [
          {
            container: 6,
            component: Drip,
            name: "Drip",
            permission: "drip",
            props: {
              lead: lead || leadId,
              refetch: refetch,
              filterData: filterData,
              setIsOpenNestModal: setIsOpenNestModal,
            },
          },
        ]
      : []),

    ...(isPermission(userPermissions, "note")
      ? [
          {
            container: 7,
            component: Note,
            name: "Note",
            permission: "note",
            props: {
              lead: lead,
              isEditPermisstion: isEditPermisstion,
              notesContainerRef: notesContainerRef,
              setIsOpenNestModal: setIsOpenNestModal,
            },
          },
        ]
      : []),

    ...(isPermission(userPermissions, "history")
      ? [
          {
            container: 8,
            component: History,
            name: "History",
            permission: "history",
            props: {
              lead: lead,
              setIsOpenNestModal: setIsOpenNestModal,
            },
          },
        ]
      : []),
  ];

  useEffect(() => {
    function sortContainersAscending(arr) {
      let myArr = [...arr];
      if (getLocalStorage("leadProfileSort")) {
        const profileSorting = JSON.parse(getLocalStorage("leadProfileSort"));
        if (isValidArray(profileSorting)) {
          myArr = arr?.map((item) => ({
            ...item,
            container: profileSorting?.find((b) => b?.name === item?.name)
              ?.container,
          }));
        }
      }
      return myArr
        ?.sort((a, b) => a?.container - b?.container)
        ?.map((arr, i) => ({ ...arr, container: i + 1 }));
    }

    setSortingWise(() => sortContainersAscending(LeadSegments) || []);
  }, [formik?.values, formik?.errors, leadData, dropChange, agentsWithPhone]);

  useEffect(() => {
    setIsOpenNestModal((prev) => ({
      ...prev,
      leadDetailsModal: isOpenAddDeatilsModal,
      birthdayModal: isOpenAddBirthdayModal,
    }));
  }, [isOpenAddDeatilsModal, isOpenAddBirthdayModal]);

  return (
    <>
      <AddDeatilsModal
        lead={lead}
        leadId={leadId ? leadId : id}
        isFetching={isFetching}
        formik={formik}
        isOpen={isOpenAddDeatilsModal}
        setIsOpen={setIsOpenAddDeatilsModal}
        editLeadSubmit={formik?.handleSubmit}
        editPhoneEmail={editPhoneEmail}
        isOpenAddBirthdayModal={isOpenAddBirthdayModal}
        setIsOpenAddBirthdayModal={setIsOpenAddBirthdayModal}
        agents={agentList}
        lenders={lenderList}
        categorys={categorys}
        tags={tags}
        sourceListOptions={sourceList}
        isLeadEditLoading={isLeadEditLoading}
        isEditPermisstion={isEditPermisstion}
        setIsEditableOpen={setIsEditableOpen}
        isEditableOpen={isEditableOpen}
        handleModalClick={handleModalClick}
        refetch={refetch}
        getAllCategory={getAllCategory}
        setIsOpenNestModal={setIsOpenNestModal}
      />
      {!isModal && <ShowPageTitle title={"leadDetails"} />}
      {!isModal ? (
        <LeadNavigation
          handleNavigationButton={handleNavigationButton}
          handleLeadNavigation={handleLeadNavigation}
        />
      ) : (
        <></>
      )}
      {!leadIsSuccess ? (
        <Loading />
      ) : (
        <div>
          <div className="grid grid-cols-1 2xl:grid-cols-3 gap-4 mb-4 mt-4 ">
            <LeadProfile
              lead={lead}
              formik={formik}
              isSubmit={true}
              handleSubmit={editPhoneEmail}
              disabled={!isEditPermisstion}
              isFetching={isFetching}
              setIsEditableOpen={setIsEditableOpen}
              isEditableOpen={isEditableOpen}
              handleModalClick={handleModalClick}
              phoneInitialValues={
                isValidArray(lead?.leadResponse)
                  ? lead?.leadResponse[0]?.phone
                  : []
              }
              phoneList={lead?.leadResponse?.[0]?.contact ?? []}
              showTags={false}
              leadId={leadId ? leadId : id}
              emailInitialValues={
                isValidArray(lead?.leadResponse)
                  ? lead?.leadResponse[0]?.email
                  : []
              }
              emailList={lead?.leadResponse?.[0]?.emailStatusDetail ?? []}
              leadRefetch={refetch}
              allCategory={getAllCategory}
              setIsOpenNestModal={setIsOpenNestModal}
            />
            <LeadStory lead={lead} />
            <LeadActivity
              lead={lead}
              leadId={leadId ? leadId : id}
              isOpenAddDeatilsModal={isOpenAddDeatilsModal}
              setIsOpenAddDeatilsModal={setIsOpenAddDeatilsModal}
              setIsOpenNestModal={setIsOpenNestModal}
            />
          </div>

          <div className="grid grid-cols-1 2xl:grid-cols-3 gap-4 ">
            {isValidArray(sortingWise) ? (
              sortingWise?.map(
                (config, key) =>
                  isPermission(userPermissions, config?.permission) && (
                    // bin is a container
                    <Container
                      key={key}
                      initialWidth={config?.width}
                      binData={{
                        ...config,
                        container: key + 1,
                        mapIndex: key + 1,
                      }}
                    >
                      <Box
                        dragData={{
                          ...config,
                          mapIndex: key + 1,
                          container: key + 1,
                        }}
                        LeadSegments={sortingWise}
                        setDropChange={setDropChange}
                      >
                        <config.component {...config?.props} />
                      </Box>
                    </Container>
                  )
              )
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default LeadDetails;
