import { EnvelopeIcon, EnvelopeOpenIcon } from "@heroicons/react/24/solid";
import moveIcon from "Assets/icons/moveIcon.svg";
import Skeleton from "Components/Common/Fields/Skeleton";
import {
  isValidArray,
  showDateAndTimeFormat,
  showLeadDateTimeFormat,
} from "Modules/util";
import { useGetEmailHistoryByLeadIdQuery } from "Redux/Leads/EmailHistory/emailHistory";
import { useEffect, useRef, useState } from "react";

export default function EmailHistory(props) {
  const { lead, drag, backgroundColor, canDrop, isDragging } = props;
  const [emailHistoryData, setEmailHistoryData] = useState();
  const emailContainerRef = useRef(null);
  const leadId = lead?.leadResponse?.[0]?._id;

  // lead wise email history list api
  const {
    data: getEmailHistoryByLeadId,
    isLoading: isEmailHistoryListLoading,
    refetch: emailHistoryListRefetch,
  } = useGetEmailHistoryByLeadIdQuery(
    {
      _id: leadId,
    },
    { skip: leadId ? false : true }
  );

  useEffect(() => {
    let emailHistoryData =
      getEmailHistoryByLeadId?.data?.getEmailHistoryByLeadId?.emailResponse;
    if (!isEmailHistoryListLoading) {
      if (isValidArray(emailHistoryData)) {
        setEmailHistoryData(emailHistoryData);
      } else {
        setEmailHistoryData([]);
      }
    }
  }, [getEmailHistoryByLeadId]);

  // UseEffect to scroll to the bottom when SMS are updated
  useEffect(() => {
    // Added minor delay which gives times to load a Dom
    const scrollTimeout = setTimeout(() => {
      if (emailContainerRef?.current) {
        emailContainerRef.current.scrollTop =
          emailContainerRef.current.scrollHeight;
      }
    }, 100);
    return () => clearTimeout(scrollTimeout);
  }, [emailHistoryData]);

  return (
    <>
      <div
        className="!min-w-[475px] w-full shadow-md rounded-2xl border-2"
        style={{
          opacity: isDragging ? 0.4 : 1,
        }}
      >
        <div
          className={`bg-teal-400 !h-[40px] rounded-t-2xl flex items-center justify-between px-4 ${
            canDrop && "!bg-teal-300 opacity-85"
          }`}
        >
          <div className="space-x-2 flex items-center">
            <div className="flex">
              <div className="bg-white w-0.5"></div>
              <div className="text-white pl-[7px] font-medium">
                Email History
              </div>
            </div>
          </div>
          <div
            className={`bg-teal-600 rounded-md flex items-center p-1.5 h-7 w-7 cursor-move`}
            ref={drag}
          >
            <img
              alt="edit-icon"
              src={moveIcon}
              className="h-4 w-4 rounded-md filter invert brightness-0"
              title={"Move Segment"}
            />
          </div>
        </div>

        <div
          className={`leadDeatils-card-body !h-[390px] rounded-b-2xl overflow-y-auto scrollbar-teal py-2 px-1 w-full space-y-2 ${
            canDrop && "!bg-teal-300 opacity-85"
          }`}
          ref={emailContainerRef}
        >
          {isEmailHistoryListLoading ? (
            <Skeleton counter={5} height={"60px"} className="my-3" />
          ) : isValidArray(emailHistoryData) ? (
            emailHistoryData?.map((email, index) => (
              <div
                key={index}
                className={`m-2 p-2 bg-gray-100 shadow-md rounded-lg space-y-2 text-xs ${
                  email?.type === "received"
                    ? "mr-10 my-3 bg-gray-100"
                    : "ml-10 !bg-teal-100"
                }`}
              >
                <div className="flex justify-between text-xs">
                  <span className="flex text-gray space-x-1">
                    <label>To:</label>
                    <label className="flex font-bold">
                      {" "}
                      <EnvelopeIcon className="w-3 text-teal-600 mr-1" />{" "}
                      {email?.to || "-"}
                    </label>
                  </span>
                  <span className="text-gray">
                    {" "}
                    {showLeadDateTimeFormat(email?.date)}
                  </span>
                </div>

                <div>
                  <div className="flex text-gray space-x-2 max-w-[75%]">
                    <div className=" text-gray-600 truncate ">
                      <span className="mt-1" title={email?.subject}>
                        <b>Subject:</b> {email?.subject}
                      </span>
                    </div>
                  </div>
                  {email?.status ? (
                    <div className=" text-gray-600 truncate ">
                      <span className="mt-1" title={email?.status}>
                        <b>Status:</b>{" "}
                        <label className="capitalize">{`${email?.status} ${
                          email.status === "not delivered"
                            ? ` (${email?.reason})`
                            : ""
                        } `}</label>
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                  {email?.type !== "received" ? (
                    <div className="flex mt-1">
                      <span className="text-gray">
                        {email?.isOpened === true ? (
                          <span className="flex items-center  text-teal-600 font-medium">
                            <EnvelopeOpenIcon className="w-4 h-4 mr-1 text-teal-600" />
                            Opened
                            <span className="pl-1 text-gray-600 font-normal text-xs mt-[2px]">
                              {showLeadDateTimeFormat(email?.openedAt)}
                              {email?.openedCount
                                ? `(${email?.openedCount} total opens)`
                                : ""}
                            </span>
                          </span>
                        ) : email?.isOpened === false ? (
                          <span className="flex items-center  text-gray-600 font-medium">
                            <EnvelopeIcon className="w-4 h-4 mr-1 mt-[2px] text-teal-600" />
                            Not Opened
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="flex justify-center items-center h-full text-gray-600 text-xs">
              No email history available.
            </div>
          )}
        </div>
      </div>
    </>
  );
}
