import OptionContext from "Components/Context/OptionContext";
import {
  deleteAllCookies,
  flushLocalStorage,
  getUserId,
  isValidArray,
  isValidObject,
} from "Modules/util";
import { useCheckUserQuery } from "Redux/user/user";
import { currentUser, refetchCurrentUser, user } from "Redux/userSlice";
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const Context = () => {
  const dispatch = useDispatch();
  let token = localStorage?.getItem("AUTH_TOKEN"); // Retrieve the JWT token from local storage
  const userPermissions = useSelector((state) => state?.user?.permission); // Get user permissions from Redux store
  const isRefetchUser = useSelector((state) => state?.user?.refetchCurrentUser); // Get user permissions from Redux store
  const userId = localStorage.getItem("USER_Id") ?? "";
  // getting current user
  const {
    data: userDeatils,
    isLoading: isUserDeatilsLoading,
    refetch: userDetailsRefetch,
  } = useCheckUserQuery(
    { _id: getUserId() },
    {
      skip: !getUserId(),
    }
  );

  //setting current user into state
  useEffect(() => {
    if (!isUserDeatilsLoading) {
      if (isValidObject(userDeatils?.data?.checkUserAccount?.response)) {
        dispatch(currentUser(userDeatils?.data?.checkUserAccount?.response));
      }
    }
  }, [userDeatils, token, userId]);

  //getting a logged in user
  useEffect(() => {
    // Check if a token exists
    if (token) {
      try {
        const decoded = jwtDecode(token); // Decode the JWT token
        // Check if the decoded token contains an 'id' user
        if (decoded?.id) {
          // Dispatch a Redux action to update the user information in the store
          dispatch(
            user({
              id: decoded?.id,
              permission: decoded?.permission,
            })
          );
        }
      } catch (error) {
        // Handle the error here, clear the invalid token from localStorage
        if (error) {
          flushLocalStorage();
          deleteAllCookies();
          window.location.reload();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]); // The effect runs whenever the 'token' variable changes

  useEffect(() => {
    if (isRefetchUser) {
      userDetailsRefetch();
      dispatch(refetchCurrentUser(false));
    }
  }, [isRefetchUser]);

  // The component doesn't render anything (empty fragment)
  if (isValidArray(userPermissions)) {
    return <OptionContext />;
  }
  return <></>;
};

export default Context;
