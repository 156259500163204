export const gqlBody = {
  login(email, password) {
    return {
      query: `mutation LoginUser($input: LoginInput!) {
        loginUser(input: $input) {
          response {
            _id
            email
            photo
            name
            firstName
            lastName
            companyName
            role
            emailVerified
            emailVerificationToken
            createdAt
            updatedAt
            financialInstitute
            phone
            agentTwilioNumber
            type
            dripFromEmail
            isAccountSetUP
            bulkFromEmail
            individualFromEmail
          }
          token
          type
          message
          code
        }
      }`,

      variables: { input: { email: email, password: password } },
    };
  },
  logout() {
    return {
      query: `mutation LogoutUser {
        logoutUser {
          response
          type
          message
          code
        }
      }`,
    };
  },
  forgotPassword(email) {
    return {
      query: `mutation ForgotPassword($input: ForgotPasswordInput!) {
        forgotPassword(input: $input) {
          type
          message
          code
        }
      }`,
      variables: { input: { email: email } },
    };
  },
  changePassword(payload) {
    return {
      query: `mutation ChangePassword($input: ChangePasswordInput!) {
        changePassword(input: $input) {
          code
          message
          type
        }
      }`,
      variables: { input: payload },
    };
  },
  updateProfile(payload) {
    return {
      query: `mutation UpdateProfile($input: UpdateProfileInput) {
        updateProfile(input: $input) {
          type
          message
          code
          userData {
            _id
            firstName
            lastName
            phone
            email
            financialInstitute
            companyName
            appPassword
            emailSignature
            dripFromEmail
            bulkFromEmail
            individualFromEmail
          }
        }
      }`,
      variables: { input: payload },
    };
  },
};
